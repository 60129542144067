import mrujs from "mrujs";
import {Turbo} from "@hotwired/turbo-rails";
import {JsErb, MrujsTurbo} from "mrujs/plugins";

import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {startStimulusApp} from "./stimulus_controllers/app";


// @ts-ignore
console.log('ADMIN: starting ... ', __BUILD_TAG__);


Turbo.session.drive = false;


mrujs.start({
    plugins: [
        MrujsTurbo(),
        // @ts-ignore
        JsErb(),
    ]
})


startStimulusApp();




if (NextLotJSDATA.load_admin_dashboard) {
    import('./index_dashboard');
}
