import {ActionEvent, Controller} from "@hotwired/stimulus";
// import {TaggedLogger} from "@nextlot/core/utilities";

// const _logger = TaggedLogger.get('FilterToggleInvoicesWithFailedPaymentsController');

export default class FilterToggleInvoicesWithFailedPaymentsController extends Controller {
    static targets = [ 'checkOnlyFailedPayments', 'checkExcludeFailedPayments' ];

    declare readonly checkOnlyFailedPaymentsTarget: HTMLInputElement;
    declare readonly checkExcludeFailedPaymentsTarget: HTMLInputElement;


    connect() {
        // _logger.debug('.connect()');
    }

    disconnect() {
        // _logger.debug('.disconnect()');
    }


    onChangeFailedPayments(event:ActionEvent) {
        if (event.target instanceof HTMLInputElement) {
            if (event.target == this.checkOnlyFailedPaymentsTarget) {
                if (this.checkOnlyFailedPaymentsTarget.checked) {
                    this.checkExcludeFailedPaymentsTarget.disabled = true;
                    this.checkExcludeFailedPaymentsTarget.checked = false;
                } else {
                    this.checkExcludeFailedPaymentsTarget.disabled = false;
                }
            } else if (event.target == this.checkExcludeFailedPaymentsTarget) {
                if (this.checkExcludeFailedPaymentsTarget.checked) {
                    this.checkOnlyFailedPaymentsTarget.disabled = true;
                    this.checkOnlyFailedPaymentsTarget.checked = false;
                } else {
                    this.checkOnlyFailedPaymentsTarget.disabled = false;
                }
            }
        }
    }




}
