import axios from 'axios';
import {NextLotADMIN} from "../../nextlot_admin_data"
import {createUppyUploaderDashboard} from "../../services/UppyUploaderService";


function renderItemMediaFileUploadForm() {
    const maxFileSizeMegabytes = parseInt(String(NextLotADMIN.DATA.limits.transloadit_max_file_size_megabytes));
    let hasSuccessfulUploads = false;

    const itemMediaFileRestrictions = {
        allowedFileTypes: ['.jpg', '.jpeg', '.png']
    }

    const uppy = createUppyUploaderDashboard({
        uppyOptions: {
            restrictions: itemMediaFileRestrictions,
            onBeforeFileAdded: (currentFile, files) => {
              if (currentFile.type?.includes('image') && currentFile.size > maxFileSizeMegabytes * 1024 * 1024) {
                uppy.info(`\`${currentFile.name}\` image exceeds the maximum recommended file size of ${maxFileSizeMegabytes} MB`, 'error', 5000)
              }
              return true;
            },
        },
        uppyDashboardOptions: {
          note: `Recommended file size up to ${maxFileSizeMegabytes} MB and file types: ${itemMediaFileRestrictions.allowedFileTypes.join(", ")}`,
        },
        uppyTransloaditOptions: {
            // WARNING ! must waitForEncoding
            waitForEncoding: true, // will enable events: 'transloadit:result', 'transloadit:complete'

            params: NextLotADMIN.DATA.transloadit?.params,
            signature: NextLotADMIN.DATA.transloadit?.signature,
        }
    });

    uppy.on('transloadit:complete', async (assembly) => {
        try {
            if (!assembly || assembly.ok !== 'ASSEMBLY_COMPLETED') {
                throw 'assembly_not_completed';
            }

            await axios.post(NextLotADMIN.DATA.urls.ajax_item_media_file_create, {
                transloadit_response: JSON.stringify(assembly),
                authenticity_token: NextLotADMIN.DATA.form_authenticity_token
            });

            uppy.info('Photo(s) uploaded successfully', 'info', 5000);
            hasSuccessfulUploads = true;
        }
        catch (ex) {
            const errorDetails = (ex === 'assembly_not_completed')
                ? `${assembly.ok}: ${assembly.message}`
                : ex.response?.data || ex.message

            uppy.info(
                'There was an error trying to upload the photo(s). Error details: ' + errorDetails,
                'error', 10*60*1000);
        }
    });

    uppy.on('dashboard:modal-closed', () => {
        if (hasSuccessfulUploads) {
            location.reload();
        }
    });
}


function attachItemMediaBatchEvents() {
    const batchSelectCheckbox = <HTMLInputElement>document.querySelector('#checkbox_media_batch_select');
    const singleSelectCheckboxes = document.querySelectorAll('[data-checkbox-media-single-select]');
    const btnBatchDelete = <HTMLButtonElement>document.querySelector('#btn_media_batch_delete');

    batchSelectCheckbox.addEventListener('change', (evt) => {
        singleSelectCheckboxes.forEach((el:HTMLInputElement) => {
            el.checked = (evt.currentTarget as HTMLInputElement).checked;
        });
        btnBatchDelete.disabled = document.querySelectorAll('[data-checkbox-media-single-select]:checked').length === 0;
    });

    singleSelectCheckboxes.forEach((el) => {
        el.addEventListener('change', () => {
            const countCheckedSingleSelectCheckboxes = document.querySelectorAll('[data-checkbox-media-single-select]:checked').length;
            batchSelectCheckbox.checked = countCheckedSingleSelectCheckboxes === singleSelectCheckboxes.length;
            btnBatchDelete.disabled = countCheckedSingleSelectCheckboxes === 0;
        });
    });

}


function renderIfNeeded() {
    if (document.getElementById('hack_render_item_media_file_upload')) {
        renderItemMediaFileUploadForm();
    }

    if (document.getElementById('checkbox_media_batch_select')) {
        attachItemMediaBatchEvents();
    }
}

export default {
    renderIfNeeded
}
