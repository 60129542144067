import {Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('LinksSideEffectsController');

type ClickableHtmlElement = HTMLAnchorElement|HTMLInputElement|HTMLButtonElement;


export default class LinksSideEffectsController extends Controller {
    static targets = [ 'delayedReloadPage' ];

    declare readonly hasDelayedReloadPageTarget: boolean;
    declare readonly delayedReloadPageTarget: ClickableHtmlElement;
    declare readonly delayedReloadPageTargets: Array<ClickableHtmlElement>;


    connect() {
        // _logger.debug('.connect() this.linkDelayedReloadPageTargets.length:', this.linkDelayedReloadPageTargets.length);

        this.delayedReloadPageTargets.forEach((el) => {
            el.addEventListener('click', handleClickEventWithDelayedReloadPage);
        })
    }

    disconnect() {
        // _logger.debug('.disconnect()');

        this.delayedReloadPageTargets.forEach((el) => {
            el.removeEventListener('click', handleClickEventWithDelayedReloadPage);
        })
    }

}


function handleClickEventWithDelayedReloadPage(event) {
    setTimeout(() => {
        window.location.reload();
    }, 500);
}
