import {ActionEvent, Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('EditToggleFormFieldController');
export default class EditToggleFormFieldController extends Controller {
    static targets = [ 'formControl' ];

    declare readonly hasFormControlTarget: boolean;
    declare readonly formControlTarget: HTMLInputElement|HTMLSelectElement;
    declare readonly formControlTargets: Array<HTMLInputElement|HTMLSelectElement>;


    connect() {
        // _logger.debug('.connect()');
    }

    disconnect() {
        // _logger.debug('.disconnect()');
    }


    onToggleEditable(event:ActionEvent) {
        if (this.hasFormControlTarget) {
            const checkBoxValue = (event.currentTarget as HTMLInputElement).checked;
            this.formControlTargets.forEach((formControlTarget) => {
                formControlTarget.disabled = !checkBoxValue;
            });
        }
    }




}
