import {
    IDeveloperSettings,
    IUser,
} from "dromo-uploader-js";
import {TaggedLogger} from "@nextlot/core/utilities";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {
    BULK_IMPORT_TYPE_ID_BY_IMPORT_IDENTIFIER,
    createDromoUploaderForAuction,
    DromoImportIdentifiersEnum
} from "../services/DromoUploaderService";


const _logger = TaggedLogger.get('DromoLotsBulkImport');



function renderDromoBulkImport_Lots() {

    const formEl = <HTMLFormElement>document.getElementById('form_lots_bulk_import');
    const formInputEl_importTypeId = formEl.querySelector('input[name=import_type_id]') as HTMLInputElement;
    const formInputEl_uploadId = formEl.querySelector('input[name=upload_id]') as HTMLInputElement;
    const formInputEl_expectedRowsCount = formEl.querySelector('input[name=expected_rows_count]') as HTMLInputElement;

    const overlayEl = document.getElementById('auction_bulk_import_overlay');

    const buttonImportLotsWithRefnoEl = document.getElementById('btn_bulk_import_with_inventory');
    const buttonImportLotsNoRefnoEl = document.getElementById('btn_bulk_import_no_inventory');


    const handleBulkImportLots = (importIdentifier:DromoImportIdentifiersEnum) => (event) => {
        event.preventDefault();

        // simple hack to temporarily disable the button to avoid accidental double-click, for a few seconds:
        // sometimes the Dromo widget takes a few seconds to show, due to slower internet, and
        // the user has the impulse to click the button again in the belief it didn't work the first time
        buttonImportLotsWithRefnoEl.classList.add('disabled');
        buttonImportLotsNoRefnoEl.classList.add('disabled');
        setTimeout(() => {
            buttonImportLotsWithRefnoEl.classList.remove('disabled');
            buttonImportLotsNoRefnoEl.classList.remove('disabled');
        }, 5000);


        const dromoSettings:IDeveloperSettings = {
            importIdentifier: importIdentifier,
            ... NextLotJSDATA.dromo_settings
        }

        const dromoUser:IUser = NextLotJSDATA.dromo_user;

        const dromo = createDromoUploaderForAuction(dromoSettings, dromoUser);

        dromo.onResults(function (dromoResponse, metadata) {
            // _logger.debug('.onResults: data:', dromoResponse);
            // _logger.debug('.onResults: metadadata:', metadata);

            overlayEl?.classList?.remove('invisible');

            formInputEl_importTypeId.value = String(BULK_IMPORT_TYPE_ID_BY_IMPORT_IDENTIFIER[importIdentifier]);
            formInputEl_uploadId.value = metadata["id"];
            formInputEl_expectedRowsCount.value = String(dromoResponse.length);

            formEl.submit();
        });

        dromo.open();

    }


    buttonImportLotsWithRefnoEl.addEventListener('click', handleBulkImportLots(DromoImportIdentifiersEnum.auction_lots_with_refno));
    buttonImportLotsNoRefnoEl.addEventListener('click', handleBulkImportLots(DromoImportIdentifiersEnum.auction_lots_no_refno));
}



function renderDromoBulkImport_Bids() {

    const buttonImportBidsEl = document.getElementById('btn_bulk_import_bids') as HTMLElement;

    if ( ! buttonImportBidsEl) {
        // if the button to import is NOT present, then skip;
        // this can happen when importing bids is not allowed, like during active timed bidding, or during webcast
        return;
    }

    const formEl = document.getElementById('form_lots_bulk_import') as HTMLFormElement;
    const formInputEl_importTypeId = formEl.querySelector('input[name=import_type_id]') as HTMLInputElement;
    const formInputEl_uploadId = formEl.querySelector('input[name=upload_id]') as HTMLInputElement;
    const formInputEl_expectedRowsCount = formEl.querySelector('input[name=expected_rows_count]') as HTMLInputElement;

    const overlayEl = document.getElementById('auction_bulk_import_overlay');

    const handleBulkImportBids = (event) => {
        _logger.debug('.renderDromoBulkImport_Bids: .handleBulkImportBids');

        event.preventDefault();

        // simple hack to temporarily disable the button to avoid accidental double-click, for a few seconds:
        // sometimes the Dromo widget takes a few seconds to show, due to slower internet, and
        // the user has the impulse to click the button again in the belief it didn't work the first time
        buttonImportBidsEl.classList.add('disabled');
        setTimeout(() => {
            buttonImportBidsEl.classList.remove('disabled');
        }, 5000);


        const dromoSettings: IDeveloperSettings = {
            importIdentifier: DromoImportIdentifiersEnum.auction_place_bids,
            ...NextLotJSDATA.dromo_settings
        }

        const dromoUser: IUser = NextLotJSDATA.dromo_user;

        const dromo = createDromoUploaderForAuction(dromoSettings, dromoUser);

        dromo.onResults(function (dromoResponse, metadata) {
            // _logger.debug('.onResults: data:', dromoResponse);
            // _logger.debug('.onResults: metadadata:', metadata);

            overlayEl?.classList?.remove('invisible');

            formInputEl_importTypeId.value = String(BULK_IMPORT_TYPE_ID_BY_IMPORT_IDENTIFIER[DromoImportIdentifiersEnum.auction_place_bids]);
            formInputEl_uploadId.value = metadata["id"];
            formInputEl_expectedRowsCount.value = String(dromoResponse.length);

            formEl.submit();
        });

        dromo.open();

    }

    buttonImportBidsEl.addEventListener('click', handleBulkImportBids);
}



export default function renderDromoLotsBulkImportIfNeeded() {

    const importLotsModalEl = document.getElementById('modal_bulk_import_lots');
    if (importLotsModalEl) {
        renderDromoBulkImport_Lots();
    }

    const importBidsModalEl = document.getElementById('modal_bulk_import_bids');
    if (importBidsModalEl) {
        renderDromoBulkImport_Bids();
    }

}
