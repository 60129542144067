import {TaggedLogger} from "@nextlot/core/utilities";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {
    createDromoUploaderForSite, DromoImportIdentifiersEnum
} from "../services/DromoUploaderService";
import { IDeveloperSettings, IUser } from "dromo-uploader-js";


const _logger = TaggedLogger.get('DromoInventoryItemsBulkImport');



function renderDromoBulkImport_InventoryItems() {

    const formEl = <HTMLFormElement>document.getElementById('form_inventory_items_bulk_import');
    const formInputEl_uploadId = <HTMLInputElement>formEl.querySelector('input[name=upload_id]');
    const formInputEl_expectedRowsCount = <HTMLInputElement>formEl.querySelector('input[name=expected_rows_count]');

    const overlayEl = document.getElementById('site_inventory_items_bulk_import_overlay');

    const buttonImportInventoryItems = document.getElementById('btn_bulk_import');


    const handleBulkImportInventoryItems = (event) => {
        event.preventDefault();
        buttonImportInventoryItems.classList.add('disabled');
        // hack to disable the button for a few seconds when Dromo widget modal loads slower due to internet
        setTimeout(() => {
            buttonImportInventoryItems.classList.remove('disabled');
        }, 5000);

        const dromoSettings:IDeveloperSettings = {
            importIdentifier: DromoImportIdentifiersEnum.inventory_items,
            ... NextLotJSDATA.dromo_settings
        }

        const dromoUser:IUser = NextLotJSDATA.dromo_user;

        const dromo = createDromoUploaderForSite(dromoSettings, dromoUser);

        dromo.onResults(function (dromoResponse, metadata) {
            // _logger.debug('.onResults: data:', dromoResponse);
            // _logger.debug('.onResults: metadadata:', metadata);

            overlayEl?.classList?.remove('invisible');

            formInputEl_uploadId.value = metadata["id"];
            formInputEl_expectedRowsCount.value = String(dromoResponse.length);

            formEl.submit();
        });

        dromo.open();
    }

    buttonImportInventoryItems.addEventListener('click', (event) => handleBulkImportInventoryItems(event));
}

export default function renderDromoInventoryItemsBulkImportIfNeeded() {
    const importOptionsModalEl = document.getElementById('modal_bulk_import_inventory_items_options');
    if (importOptionsModalEl) {
        renderDromoBulkImport_InventoryItems();
    }
}
