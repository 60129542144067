import {runOnDOMContentLoaded, TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('form_bidder_invoicing_auctions');








function renderIfNeeded() {
    runOnDOMContentLoaded(function () {
        const formEl = document.getElementById('form_bidder_invoicing_auctions');
        if (formEl) {
            // _logger.debug('.renderIfNeeded>runOnDOMContentLoaded> form_bidder_invoicing_auctions: ', formEl);

            const elButton_submit = formEl.querySelector('button[type=submit]') as HTMLButtonElement;
            const elSpan_selectedCount = formEl.querySelector('#span_selected_auctions_count') as HTMLSelectElement;

            const elDiv_multipleAuctions = formEl.querySelector('#div_multiple_auctions_selected') as HTMLDivElement;
            const elSelect_primaryAuction = formEl.querySelector('#select_primary_auction') as HTMLSelectElement;

            const selectedAuctionsMap:Map<number, string> = new Map();

            formEl.querySelectorAll('input[type=checkbox][data-auction-label]').forEach((elCkb:HTMLInputElement) => {
                const auctionId = parseInt(elCkb.value);
                const auctionLabel = elCkb.dataset.auctionLabel as string;
                elCkb.addEventListener('change', (evt) => {
                    const ckb = evt.target as HTMLInputElement;
                    _logger.debug('change: ', auctionId, '>', ckb.checked);
                    if (ckb.checked) {
                        selectedAuctionsMap.set(auctionId, auctionLabel);
                    }
                    else {
                        selectedAuctionsMap.delete(auctionId);
                    }

                    const selectedAuctionsCount = selectedAuctionsMap.size;
                    elSpan_selectedCount.textContent = selectedAuctionsCount.toString();

                    elSelect_primaryAuction.options.length = 0; // remove all options

                    if (selectedAuctionsCount > 1) {
                        elDiv_multipleAuctions.classList.remove('d-none');

                        selectedAuctionsMap.forEach((auctionLabel, auctionId) => {
                            const elOpt = document.createElement('option');
                            elOpt.value = auctionId.toString();
                            elOpt.text = auctionLabel;
                            elSelect_primaryAuction.options.add(elOpt);
                        })
                    }
                    else {
                        elDiv_multipleAuctions.classList.add('d-none');


                        // enable submit when at least one auction selected
                        elButton_submit.disabled = (selectedAuctionsCount === 0);
                    }
                });
            })
        }
    });
}

export default {
    renderIfNeeded
}
