import {ActionEvent, Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('PaddleNumberController');


export default class PaddleNumberController extends Controller {
    static targets = ['input', 'checkbox'];

    private readonly inputTarget: HTMLInputElement;
    private readonly checkboxTarget: HTMLInputElement;


    checkboxTargetConnected(element: HTMLSelectElement) {
        element.addEventListener('change', this.onUseCheckChange)
    }

    checkboxTargetDisconnected(element: HTMLSelectElement) {
        element.removeEventListener('change', this.onUseCheckChange)
    }



    onUseCheckChange = (event: ActionEvent) => {
      if (this.checkboxTarget.checked) {
        this.inputTarget.classList.add('d-none')
        this.inputTarget.disabled = true;
        this.inputTarget.value = '';
      } else {
        const message = "Add custom paddle number?";
        if (!confirm(message)) {
          this.checkboxTarget.checked = true;
        } else {
          this.inputTarget.value = '';
          this.inputTarget.classList.remove('d-none');
          this.inputTarget.disabled = false;
        }
      }

    }
}

