import {NextLotADMIN} from "../../nextlot_admin_data"
import {createUppyUploaderDashboard} from "../../services/UppyUploaderService";

function renderFormBannerImageUpload(formEl:HTMLFormElement) {
    const maxFileSizeMegabytes = parseInt(String(NextLotADMIN.DATA.limits.transloadit_max_file_size_megabytes));

    const uppySiteLogoBannerUploadRestrictions = {
        maxNumberOfFiles: 1,
        allowMultipleUploadBatches: false,
        maxFileSize: maxFileSizeMegabytes * 1024 * 1024,
        allowedFileTypes: ['.png'],
    }

    const uppy = createUppyUploaderDashboard({
        uppyOptions: {
            restrictions: uppySiteLogoBannerUploadRestrictions
        },
        uppyTransloaditOptions: {
            waitForEncoding: true, // must set `true` to have access to event 'transloadit:complete'

            params: NextLotADMIN.DATA.transloadit.params,
            signature: NextLotADMIN.DATA.transloadit.signature,
        }
    });

    uppy.on('transloadit:complete', (assembly) => {

        const inputWebcastLogoUrl400 = <HTMLInputElement>document.getElementById('site_logo_banner_url_400_X_100');
        inputWebcastLogoUrl400.value = assembly.results[NextLotADMIN.DATA.transloadit.encoding_steps.step_optimized_logo_banner_400_X_100][0].ssl_url;

        const inputWebcastLogoUrl200 = <HTMLInputElement>document.getElementById('site_logo_banner_url_200_X_50');
        inputWebcastLogoUrl200.value = assembly.results[NextLotADMIN.DATA.transloadit.encoding_steps.step_optimized_logo_banner_200_X_50][0].ssl_url;

        formEl.submit();
    })
}


function renderIfNeeded() {
    const formEl = document.getElementById('form_site_logo_banner_upload') as HTMLFormElement;
    if (formEl) {
      renderFormBannerImageUpload(formEl);
    }
}

export default {
    renderIfNeeded
}
