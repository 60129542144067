import {NextLotADMIN} from "../../nextlot_admin_data"
import {createUppyUploaderDashboard} from "../../services/UppyUploaderService";

function renderFormAuctionThumbImageUpload(formElement:HTMLFormElement) {
    const maxFileSizeMegabytes = parseInt(String(NextLotADMIN.DATA.limits.transloadit_max_file_size_megabytes));

    const auctionThumbImageRestrictions = {
        maxNumberOfFiles: 1,
        allowMultipleUploadBatches: false,
        maxFileSize: maxFileSizeMegabytes * 1024 * 1024,
        allowedFileTypes: ['.jpg', '.jpeg', '.png']
    }

    const uppy = createUppyUploaderDashboard({
        uppyOptions: {
            restrictions: auctionThumbImageRestrictions
        },
        uppyTransloaditOptions: {
            waitForEncoding: true, // must set `true` to have access to event 'transloadit:complete'

            params: NextLotADMIN.DATA.transloadit.params,
            signature: NextLotADMIN.DATA.transloadit.signature,
        }
    });

    uppy.on('transloadit:complete', (assembly) => {
        const inputUrlHighdefImage = <HTMLInputElement>document.getElementById('site_url_highdef_image');
        inputUrlHighdefImage.value = assembly.results[NextLotADMIN.DATA.transloadit.encoding_steps.step_img_large][0].ssl_url;

        const inputUrlImage = <HTMLInputElement>document.getElementById('site_url_large_image');
        inputUrlImage.value = assembly.results[NextLotADMIN.DATA.transloadit.encoding_steps.step_img_large][0].ssl_url;

        const inputUrlThumbImage = <HTMLInputElement>document.getElementById('site_url_thumb_image');
        inputUrlThumbImage.value = assembly.results[NextLotADMIN.DATA.transloadit.encoding_steps.step_optimized_img_thumbnail][0].ssl_url;

        formElement.submit();
    });
}


function renderIfNeeded() {
    const formEl = document.getElementById('form_site_thumb_image_upload') as HTMLFormElement;
    if (formEl) {
        renderFormAuctionThumbImageUpload(formEl);
    }
}

export default {
    renderIfNeeded
}
