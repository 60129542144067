import { createUppyUploaderDashboard } from "../../services/UppyUploaderService";
import { NextLotADMIN } from "../../nextlot_admin_data";

function renderFormAuctionThumbImageUpload(formElement: HTMLFormElement) {

    const maxFileSizeMegabytes = parseInt(
        String(NextLotADMIN.DATA.limits.transloadit_max_file_size_megabytes)
    );

    const auctionThumbImageRestrictions = {
        maxNumberOfFiles: 1,
        allowMultipleUploadBatches: false,
        maxFileSize: maxFileSizeMegabytes * 1024 * 1024,
        allowedFileTypes: [".jpg", ".jpeg", ".png"],
    };

    const uppy = createUppyUploaderDashboard({
        uppyOptions: {
            restrictions: auctionThumbImageRestrictions,
        },
        uppyTransloaditOptions: {
            waitForEncoding: true, // must set `true` to have access to event 'transloadit:complete'

            params: NextLotADMIN.DATA.transloadit.params,
            signature: NextLotADMIN.DATA.transloadit.signature,
        },
    });

    uppy.on("transloadit:complete", (assembly) => {
        const inputUrlThumbImage = <HTMLInputElement>(
            document.getElementById("auction_url_thumb_image")
        );
        inputUrlThumbImage.value =
            assembly.results[
                NextLotADMIN.DATA.transloadit.encoding_steps.step_optimized_img_thumbnail
                ][0].ssl_url;

        formElement.submit();
    });
}


function renderIfNeeded() {
    const formEl = document.getElementById('form_auction_thumb_image_upload') as HTMLFormElement;
    if (formEl) {
        renderFormAuctionThumbImageUpload(formEl)
    }
}

export default {
    renderIfNeeded
}
