import React, { Reducer} from "react";
import ReactDOM from "react-dom/client";
import {v4 as uuidv4} from 'uuid';
import classNames from "classnames";
import {numberAsString, runOnDOMContentLoaded, TaggedLogger} from "@nextlot/core/utilities";


const _logger = TaggedLogger.get('forms::BidIncrementsTableForm');


type ValidatedData_TypeDef = {
    table: Array<TableRow_TypeDef>,
    validationError?:string|null,
}


function renderIfNeeded() {
    runOnDOMContentLoaded(function () {

        const formEl = document.getElementById('form_bid_increments_table');
        if (formEl) {
            _logger.debug('.renderIfNeeded>runOnDOMContentLoaded> BidIncrementsTableForm: ', formEl);
            const cmpRootEl:HTMLElement = formEl.querySelector('div[data-cmp-root]');

            const tableFieldName:string = cmpRootEl.dataset.tableFieldName;
            const elHiddenInput:HTMLInputElement = formEl.querySelector(`input[name="${tableFieldName}"]`);
            const jsonStr = elHiddenInput.value;
            // _logger.debug('>>>>>: jsonStr:', jsonStr);
            const tuplesArray = JSON.parse(jsonStr);

            const elSubmitBtn:HTMLButtonElement = formEl.querySelector('button[type=submit]');

            const handleChangeBidIncrementsTable = (validatedData:ValidatedData_TypeDef) => {

                if (validatedData.validationError) {
                    elSubmitBtn.disabled = true;
                    elHiddenInput.value = null;
                }
                else {
                    elSubmitBtn.disabled = false;
                    const tuplesArray:Array<[number, number]> = validatedData.table.map((tr) => ([tr.limit, tr.increment]))
                    elHiddenInput.value = JSON.stringify(tuplesArray);
                    // _logger.debug('.handleChangeBidIncrementsTable: VALID!! ', tuplesArray);
                }


            }
            ReactDOM.createRoot(cmpRootEl).render(
                <BidIncrementsTableCmp
                    initTuplesArray={tuplesArray}
                    onChange={handleChangeBidIncrementsTable}
                />
            );
        }

    });
}

export default {
    renderIfNeeded
}


type TuplesArray_TypeDef = Array<[number, number]>;

type CmpProps_TypeDef = {
    initTuplesArray: TuplesArray_TypeDef,
    onChange: (ValidatedData_TypeDef)=>void,
}


type TableRow_TypeDef = {
    rowKey:string,
    limit:number,
    increment:number,
    validationError?:string|null|boolean,
}




function initializeTableRows(tuplesArray:TuplesArray_TypeDef):Array<TableRow_TypeDef> {
    return tuplesArray.length
        ? tuplesArray
            .sort((a, b) => {
                return (a[0] - b[0]); // sort ascending by 'limit' field
            })
            .map((t, idx) => ({
                rowKey: uuidv4(),
                limit: idx === 0 ? 0 : parseFloat(String(t[0] || 0)),
                increment: parseFloat(String(t[1] || 1)),
            }))
        : [{ rowKey: uuidv4(), limit: 0, increment: 1 }];
}


function BidIncrementsTableCmp(props:CmpProps_TypeDef) {

    const [table, dispatch] =
        React.useReducer<
            Reducer<Array<TableRow_TypeDef>, {type:string, payload:{ rowIdx: number, value?:number }}>,
            TuplesArray_TypeDef>
        ((state, action) => {
            switch(action.type) {

                case 'row/add': {
                    const { rowIdx } = action.payload;
                    const prevRow = state[rowIdx];
                    return [
                        ... state.slice(0, rowIdx+1),
                        {
                            ... prevRow,
                            limit: prevRow.limit + 1,
                            rowKey: uuidv4(),
                            validationError: undefined,
                        },
                        ... state.slice(rowIdx+1),
                    ]
                }



                case 'row/remove': {
                    const { rowIdx } = action.payload;

                    if (state.length <= 1) {
                        // handle delete last row, do not allow delete of last row, just put zero values
                        return [{
                            rowKey: uuidv4(),
                            limit: 0,
                            increment: 1,
                        }]
                    }

                    // we have at least 2+ rows

                    if (rowIdx === 0) {
                        // removing the 1st row,
                        return [
                            {
                                // must set the limit to 0
                                ... state[1],
                                limit: 0,
                            }
                            ,
                            ... state.slice(2),
                        ]
                    }

                    return state.filter((_tr, idx) => (idx !== rowIdx));
                }



                case 'row/update/limit': {
                    const { rowIdx, value } = action.payload;
                    return state.map((tr, idx) => {
                        if (idx === rowIdx) {
                            return {
                                ... tr,
                                limit: value,
                            }
                        }
                        else {
                            return tr;
                        }
                    })
                }

                case 'row/update/increment': {
                    const { rowIdx, value } = action.payload;
                    return state.map((tr, idx) => {
                        if (idx === rowIdx) {
                            return {
                                ... tr,
                                increment: value,
                            }
                        }
                        else {
                            return tr;
                        }
                    })
                }
            }

            return state;

        }, props.initTuplesArray, initializeTableRows);


    const [validatedData, setValidatedData] = React.useState({ table: [] });



    React.useEffect(() => {

        let ptr:TableRow_TypeDef = null;

        const invalidRows:Set<string> = new Set([]);

        table.sort((a, b) => (a.limit - b.limit)) // sort ascending by `limit`
            .forEach((ctr) => {
                if (ptr) {
                    if (ctr.limit <= ptr.limit) {
                        // current limit may not be <= than previous limit
                        invalidRows.add(ctr.rowKey);
                    }
                    else if (ctr.limit <= ptr.increment) {
                        // previous increment may not be greater than the current limit
                        invalidRows.add(ptr.rowKey);
                    }
                    else if (!ctr.limit || !ctr.increment) {
                        //increment and limit must have a value
                        invalidRows.add(ctr.rowKey);
                    }
                    else {
                        ptr.validationError = null;
                    }
                }
                ptr = ctr;
                return ctr;
            });

        const validationError = invalidRows.size ? 'invalid' : false;

        const validatedTable:TableRow_TypeDef[] = table
            .map(tr => ({
                ... tr,
                validationError: invalidRows.has(tr.rowKey),
            }));


        const validatedData = {
            validationError: validationError,
            table: validatedTable,
        };

        setValidatedData(validatedData)

        props.onChange(validatedData);

    }, [table]);



    const handleRowChangeRawInput = (rowIdx:number, field:string) => (evt) => {
        const rawValue:string = evt.target.value;
        const numberValue = parseFloat(rawValue);
        dispatch({type: `row/update/${field}`, payload: { rowIdx: rowIdx, value: numberValue }});
    }

    const handleRowClickAddRemove = (rowIdx:number, operation:string) => (evt) => {
        evt.preventDefault();
        dispatch({type: `row/${operation}`, payload: { rowIdx: rowIdx }});
    }

    return (
        <div>
            {
                validatedData.table.map((tr: TableRow_TypeDef, rowIdx) => {
                    return (
                        <div key={tr.rowKey}
                             className={classNames('mb-2 py-1 d-flex flex-row', {'border-danger bg-warning': tr.validationError})}>

                            <div className='d-flex flex-row align-items-center'>

                                <div className='input-group'>
                                    <span className="input-group-text">Starting at</span>

                                    <input type='number' className='form-control'
                                           min={0}
                                           step={0.50}
                                           value={numberAsString(tr.limit)}
                                           onChange={handleRowChangeRawInput(rowIdx, 'limit')}
                                           disabled={rowIdx === 0}
                                    />
                                </div>

                                <div className='ms-3 input-group'>
                                    <span className="input-group-text">Increment</span>

                                    <input type='number' className='form-control'
                                           min={0.01}
                                           step={0.01}
                                           value={numberAsString(tr.increment)}
                                           onChange={handleRowChangeRawInput(rowIdx, 'increment')}
                                    />
                                </div>

                                <button type='button' className='ms-3 px-2 btn btn-sm btn-outline-danger'
                                        onClick={handleRowClickAddRemove(rowIdx, 'remove')}>&times;</button>
                            </div>

                            <button type='button' className='ms-3 btn btn-sm btn-outline-secondary'
                                    onClick={handleRowClickAddRemove(rowIdx, 'add')}>Add row after
                            </button>

                        </div>
                    )
                })
            }
        </div>
    )

}
