import CodeFlask from "codeflask";

function renderCodeFlask(hiddenInputElement) {

    const codeEditorContainerEl = document.createElement('div');
    const codeEditorCustomHeight:string = hiddenInputElement.getAttribute('data-code-editor-height');

    codeEditorContainerEl.classList.add('code-editor--codeflask');
    codeEditorContainerEl.style.height = codeEditorCustomHeight; // will be overridden by the .code-editor--codeflask CSS class if empty or not defined.
    hiddenInputElement.after(codeEditorContainerEl);

    const editor = new CodeFlask(codeEditorContainerEl, {
        lineNumbers: !hiddenInputElement.dataset.codeEditorLineNumbersHide,
        language: hiddenInputElement.dataset.codeEditorLanguage || 'html',
    });

    editor.updateCode(hiddenInputElement.value);
    editor.onUpdate((code) => {
        hiddenInputElement.value = code;
    });
}


function renderIfNeeded() {
    document.querySelectorAll('input[type=hidden][data-acts-as-code-editor]').forEach((el) => {
        renderCodeFlask(el);
    });
}



export default {
    renderIfNeeded
}
