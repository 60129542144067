import { NextLotADMIN } from "../../nextlot_admin_data";
import { TaggedLogger } from "@nextlot/core/utilities";
import { createUppyUploaderDashboard } from "../../services/UppyUploaderService";

const _logger = TaggedLogger.get("AuctionsItemMediaFilesBulkImportUpload");



function renderItemMediaFilesBulkImportUploadForm(formEl:HTMLFormElement) {
    const maxImageFileSizeMegabytes = parseInt(String(NextLotADMIN.DATA.limits.transloadit_max_file_size_megabytes));
    const maxZipFileSizeMegabytes = parseInt(String(NextLotADMIN.DATA.limits.transloadit_max_zip_file_size_megabytes));

    const itemMediaFileRestrictions = {
        allowedFileTypes: ['.zip', '.jpg', '.jpeg', '.png'],
        imageMaxFileSize: maxImageFileSizeMegabytes * 1024 * 1024,
        zipMaxFileSize: maxZipFileSizeMegabytes * 1024 * 1024,
    }

    const uppy = createUppyUploaderDashboard({
        uppyOptions: {
            restrictions: {
              allowedFileTypes: itemMediaFileRestrictions.allowedFileTypes
            },
            onBeforeFileAdded: (currentFile, files) => {
              if (currentFile.type?.includes('image') && currentFile.size > itemMediaFileRestrictions.imageMaxFileSize) {
                uppy.info(`\`${currentFile.name}\` image exceeds the recommended file size of ${maxImageFileSizeMegabytes} MB`, 'error', 5000)
              }
              if (currentFile.type?.includes('zip') && currentFile.size > itemMediaFileRestrictions.zipMaxFileSize) {
                uppy.info(`\`${currentFile.name}\`  ZIP exceeds the recommended file size of ${maxZipFileSizeMegabytes} MB`, 'error', 5000)
              }

              return true;
            },
        },
        uppyDashboardOptions: {
            trigger: '#btn_trigger_uppy_dashboard_item_media_files_bulk_import',
            note: `Allowed file types: ${itemMediaFileRestrictions.allowedFileTypes.join(", ")}. `+
                `Recommended file sizes: up to ${NextLotADMIN.DATA.limits.transloadit_max_zip_file_size_megabytes} MB for ZIP archives ` +
                ` and ${NextLotADMIN.DATA.limits?.transloadit_max_file_size_megabytes} MB for images.`,
        },
        uppyTransloaditOptions: {
            // WARNING !  do not wait for encoding, we need to allow
            waitForEncoding: false,  // false will not trigger events: 'transloadit:result', 'transloadit:complete'
            waitForMetadata: false,  // false will not trigger events: 'transloadit:upload'

            params: NextLotADMIN.DATA.transloadit?.params,
            signature: NextLotADMIN.DATA.transloadit?.signature,
        }
    });

    const formInputEl_uploadId = formEl.querySelector('input[name=upload_id]') as HTMLInputElement;
    const formInputEl_importTypeId = formEl.querySelector('input[name=import_type_id]') as HTMLInputElement;

    // uppy.on('transloadit:assembly-created', (assembly, fileIDs) => {
    //     _logger.debug('> created', assembly);
    // });
    // uppy.on('transloadit:result', () => {
    //     _logger.debug('> transloadit:result: args', arguments);
    // });
    // uppy.on('transloadit:complete', () => {
    //     _logger.debug('> transloadit:complete: args', arguments);
    // });
    // uppy.on('transloadit:upload', (file, assembly) => {
    //     _logger.debug('> transloadit:upload: args', arguments);
    // });

    uppy.on('complete', (result) => {
        // _logger.debug('> complete: ', );
        // formInputEl_importTypeId.value = String(NextLotADMIN.DATA.)

        // @ts-ignore
        const assemblyId = result.transloadit[0].assembly_id;
        formInputEl_uploadId.value = assemblyId;

        formEl.submit();
    });
}





function renderIfNeeded() {

    const formEl_site = document.getElementById('form_site_inventory_items_imf_bulk_import_reports') as HTMLFormElement;
    if (formEl_site) {
        renderItemMediaFilesBulkImportUploadForm(formEl_site);
    }


    const formEl_auction = document.getElementById('form_auction_lots_imf_bulk_import_reports') as HTMLFormElement;
    if (formEl_auction) {
        renderItemMediaFilesBulkImportUploadForm(formEl_auction);
    }
}

export default {
    renderIfNeeded
}
