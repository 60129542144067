import {useState, useReducer, useEffect} from 'react';
import ReactDOM from "react-dom/client";
import {v4 as uuidv4} from 'uuid';
import classNames from "classnames";
import {numberAsString, runOnDOMContentLoaded, TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('forms::BuyerPremiumsForm');

const MAX_PREMIUM_AMOUNT = 99999999;
// manually keep in sync with BuyerPremiumCalculator::FIELD_TYPE_VALUES
enum premiumType_Enum {
    amount = 'amount',
    perc_whole = 'perc:whole',
    perc_cumul = 'perc:cumul',
    perc_fixed = 'perc:fixed'
}

function renderIfNeeded() {
    runOnDOMContentLoaded(function () {

        const formEl = document.getElementById('form_buyer_premiums');
        if (formEl) {
            // _logger.debug('.renderIfNeeded>runOnDOMContentLoaded> BuyerPremiumsForm: ', formEl);

            document.querySelectorAll('div[data-cmp-root]').forEach((cmpRootEl:HTMLElement) => {
                renderBuyerPremiumsCmp(formEl, cmpRootEl);
            });
        }
    });
}

function renderBuyerPremiumsCmp(formEl, cmpRootEl) {
    const fieldName:string = cmpRootEl.dataset.fieldName;
    const elHiddenInput:HTMLInputElement = formEl.querySelector(`input[name="${fieldName}"]`);
    const submitButton = document.getElementById('form_buyer_premiums_submit_button')
    const jsonStr = elHiddenInput.value;
    // _logger.debug('>>>>>: jsonStr:', jsonStr);
    const buyerPremiumsData = jsonStr ? JSON.parse(jsonStr) : {};


    const handleChangeState = (validatedState: ValidatedState_TypeDef) => {
        // _logger.debug('.handleChangeState[validatedState]: ', validatedState);
        if (validatedState.scaleValidationError || validatedState.capAmountValidationError) {
            elHiddenInput.value = null;
            submitButton.classList.add('disabled');
        } else {
            submitButton.classList.remove('disabled');
            let convertedScaleStepsArray: Array<[number, number, number]>;

            if (validatedState.data.scaleArray.length > 0 && (validatedState.data.premiumType !== premiumType_Enum.amount)) {
                convertedScaleStepsArray = validatedState.data.scaleArray.map((tr) => ([tr.upToAmount, tr.percent, tr.minAmount]))
            } else {
                convertedScaleStepsArray = null;
            }

            let validatedDataString;
            if (validatedState.data.premiumType) {
                let validatedDataObj = { type: validatedState.data.premiumType };
                if (validatedState.data.premiumType != premiumType_Enum.amount)
                    validatedDataObj['scale'] = convertedScaleStepsArray;
                if (validatedState.data.capAmount)
                    validatedDataObj['cap_amount'] = validatedState.data.capAmount;

                validatedDataString = JSON.stringify(validatedDataObj);
            } else {
                validatedDataString = ''
            }

            elHiddenInput.value = validatedDataString;
            // _logger.debug('.handleChangeState: VALID!! ', validatedDataString);
        }
    }

    ReactDOM.createRoot(cmpRootEl).render(
        <BuyerPremiumsCmp
            initData={buyerPremiumsData}
            onChange={handleChangeState}
        />);
}

export default {
    renderIfNeeded
}


type ScaleStepRow_TypeDef = {
    rowKey: string,
    percent: number,
    upToAmount: number,
    minAmount?: number,
    validationError?: string|null|boolean,
}

type StateData_TypeDef = {
    premiumType: string,
    scaleArray: Array<ScaleStepRow_TypeDef>,
    capAmount: number,
};

type ValidatedState_TypeDef = {
    data: StateData_TypeDef,
    scaleValidationError: boolean,
    capAmountValidationError: boolean,
}

type InitScaleStepArray_TypeDef = Array<[number, number, number]>;
type InitData_TypeDef = {
    type?: string,
    scale?: InitScaleStepArray_TypeDef,
    cap_amount?: number,
};


type CmpProps_TypeDef = {
    initData: InitData_TypeDef,
    onChange: (ValidatedState_TypeDef)=>void,
}





function createInitialState(data: InitData_TypeDef): StateData_TypeDef {
    // _logger.debug('createInitialState: data:', data);
    return {
        premiumType: data?.type,
        scaleArray: data?.scale?.length
            ? data.scale
                .sort((a, b) => {
                    return (a[0] - b[0]); // sort ascending by 'upToAmount' field
                })
                .map((t, idx) => ({
                    rowKey: uuidv4(),
                    upToAmount: parseFloat(String(t[0] || 0)),
                    percent: parseFloat(String(t[1] || 0)),
                    minAmount: parseFloat(String(t[2])),
                }))
            : [],
        capAmount: data?.cap_amount
    }
}

function reducer(state, action) {
    switch(action.type) {

        case 'row/add': {
            const { rowIdx } = action.payload;
            const prevRow = state.scaleArray[rowIdx];
            return {
                ...state,
                scaleArray: [
                    ... state.scaleArray.slice(0, rowIdx+1),
                    {
                        ... prevRow,
                        upToAmount: prevRow.upToAmount < MAX_PREMIUM_AMOUNT - 1 ? prevRow.upToAmount + 1 : prevRow.upToAmount,
                        percent: prevRow.percent,
                        minAmount: '',
                        rowKey: uuidv4(),
                        validationError: undefined,
                    },
                    ... state.scaleArray.slice(rowIdx+1),
                ]
            }
        }



        case 'row/remove': {
            const { rowIdx } = action.payload;

            if (state.scaleArray.length <= 1) {
                // handle delete last row, do not allow delete of last row, just put zero values
                return {
                    ...state,
                    scaleArray: [
                        {
                            rowKey: uuidv4(),
                            upToAmount: undefined,
                            percent: undefined,
                            minAmount: undefined
                        }
                    ]
                }
            }

            return {
                ...state,
                scaleArray: state.scaleArray.filter((_tr, idx) => (idx !== rowIdx))
            }
        }



        case 'row/update/premiumType': {
            const { value } = action.payload;
            let newScaleArray;
            let capAmount;
            if (value === '' || value === premiumType_Enum.amount) {
                newScaleArray = []
                capAmount = 1
            } else if (value === premiumType_Enum.perc_fixed) {
                newScaleArray = [{ rowKey: uuidv4(), upToAmount: 1, percent: 1 }]
            } else {
                newScaleArray = [{ rowKey: uuidv4(), upToAmount: 1, percent: 1 }, { rowKey: uuidv4(), upToAmount: MAX_PREMIUM_AMOUNT, percent: 1 }]
            }
            return {
                premiumType: value,
                scaleArray: newScaleArray,
                capAmount: capAmount,
            }
        }

        case 'row/update/capAmount': {
            const { value } = action.payload;
            return {
                ...state,
                capAmount: value
            }
        }

        case 'row/update/percent': {
            const { rowIdx, value } = action.payload;
            return {
                ...state,
                scaleArray: state.scaleArray.map((tr, idx) => {
                    if (idx === rowIdx) {
                        return {
                            ... tr,
                            percent: value,
                        }
                    }
                    else {
                        return tr;
                    }
                })
            }
        }

        case 'row/update/upToAmount': {
            const { rowIdx, value } = action.payload;
            return {
                ...state,
                scaleArray: state.scaleArray.map((tr, idx) => {
                    if (idx === rowIdx) {
                        return {
                            ... tr,
                            upToAmount: value,
                        }
                    }
                    else {
                        return tr;
                    }
                })
            }
        }

        case 'row/update/minAmount': {
            const { rowIdx, value } = action.payload;
            return {
                ...state,
                scaleArray: state.scaleArray.map((tr, idx) => {
                    if (idx === rowIdx) {
                        return {
                            ... tr,
                            minAmount: value,
                        }
                    }
                    else {
                        return tr;
                    }
                })
            }
        }

    }

    return state;

}

function BuyerPremiumsCmp(props: CmpProps_TypeDef) {

    const [state, dispatch] = useReducer(reducer, props.initData, createInitialState);

    const [validatedState, setValidatedState] = useState({ scaleValidationError: false, capAmountValidationError: false, data: {premiumType: undefined, scaleArray: [], capAmount: undefined} });



    useEffect(() => {
        // _logger.debug('#useEffect: state:', state);
        let ptr:ScaleStepRow_TypeDef = null;

        const invalidRows:Set<string> = new Set([]);

        state.scaleArray.sort((a, b) => (a.upToAmount - b.upToAmount)) // sort ascending by `upToAmount`
            .forEach((ctr) => {
                if (isNaN(ctr.upToAmount) || isNaN(ctr.percent)) {
                    //upToAmount and percent must have a value
                    invalidRows.add(ctr.rowKey);
                }

                if (ptr) {
                    if (ctr.upToAmount <= ptr.upToAmount) {
                        // current limit may not be <= than previous limit
                        invalidRows.add(ctr.rowKey);
                    } else {
                        ptr.validationError = null;
                    }
                }
                ptr = ctr;
                return ctr;
            });

        let scaleValidationError: boolean = invalidRows.size ? true : false;
        let capAmountValidationError: boolean = false;

        if ((state.premiumType == premiumType_Enum.amount && !state.capAmount) || (state.capAmount != undefined && state.capAmount != null && state.capAmount < 1)) {
            capAmountValidationError = true;
        }

        const newValidatedStateData: StateData_TypeDef = {
            ...state,
            scaleArray: state.scaleArray.map(tr => ({...tr, validationError: invalidRows.has(tr.rowKey)}))
        };


        const newValidatedState: ValidatedState_TypeDef = {
            scaleValidationError: scaleValidationError,
            capAmountValidationError: capAmountValidationError,
            data: newValidatedStateData,
        };

        setValidatedState(newValidatedState)

        props.onChange(newValidatedState);

    }, [state]);




    const handleRowChangeRawInput = (rowIdx:number, field:string) => (evt) => {
        const rawValue: string = evt.target.value >= MAX_PREMIUM_AMOUNT ? MAX_PREMIUM_AMOUNT - 1 : evt.target.value;
        const numberValue = parseFloat(rawValue);
        dispatch({type: `row/update/${field}`, payload: { rowIdx: rowIdx, value: numberValue }});
    }

    const handleRowClickAddRemove = (rowIdx:number, operation:string) => (evt) => {
        evt.preventDefault();
        dispatch({type: `row/${operation}`, payload: { rowIdx: rowIdx }});
    }

    const onChangeCapInput = (evt) => {
        const rawValue: string = evt.target.value;
        const numberValue = parseFloat(rawValue);
        dispatch({type: 'row/update/capAmount', payload: { value: numberValue }});
    }


    const shouldDisplayScale = validatedState.data.premiumType != premiumType_Enum.amount
    const shouldDisplayUpToInput = validatedState.data.premiumType != premiumType_Enum.perc_fixed
    const shouldDisplayRemoveButton = validatedState.data.premiumType != premiumType_Enum.perc_fixed
    const isTypeSelected = validatedState.data.premiumType != null && validatedState.data.premiumType !== ''
    const shouldDisplayAddButton = isTypeSelected && validatedState.data.premiumType != premiumType_Enum.perc_fixed && validatedState.data.premiumType != premiumType_Enum.amount


    return (

        <div>
            <label className='form-label'>Type</label>
            <select
                className="form-select"
                value={validatedState.data.premiumType || ''}
                onChange={e => dispatch({ type: 'row/update/premiumType', payload: { value: e.target.value } })}
            >
                <option value=''>No premium</option>
                <option value={`${premiumType_Enum.amount}`}>Flat amount</option>
                <option value={`${premiumType_Enum.perc_fixed}`}>Fixed percentage (may have cap)</option>
                <option value={`${premiumType_Enum.perc_whole}`}>Sliding scale (may have cap, applies on whole amount)</option>
                <option value={`${premiumType_Enum.perc_cumul}`}>Cumulative sliding scale (may have cap)</option>
            </select>

            {
                isTypeSelected && shouldDisplayScale && <div className='pt-4' style={{marginBottom: "-1rem"}}>Scale</div>
            }


            {
                shouldDisplayScale && validatedState.data.scaleArray.map((tr: ScaleStepRow_TypeDef, rowIdx) => {
                    return (
                        <div key={tr.rowKey}
                             className={classNames('py-3 row gx-3', {'border-danger bg-warning': tr.validationError})}>

                            {
                                shouldDisplayUpToInput &&
                                <div className={classNames('col', {'d-flex align-items-center': tr.upToAmount == MAX_PREMIUM_AMOUNT})}>
                                    { rowIdx == 0 && <label className="form-label">Up to</label> }
                                    {
                                        tr.upToAmount == MAX_PREMIUM_AMOUNT ?
                                            <span className="align-middle">anything above {isNaN(validatedState.data.scaleArray[rowIdx-1]?.upToAmount) ? '' : validatedState.data.scaleArray[rowIdx-1].upToAmount}</span>
                                            :
                                            <input type='number' className='form-control'
                                                   min={1}
                                                   step={0.01}
                                                   value={numberAsString(tr.upToAmount)}
                                                   onChange={handleRowChangeRawInput(rowIdx, 'upToAmount')}
                                            />
                                    }

                                </div>
                            }

                            <div className="col">
                                { rowIdx ==0 && <label className="form-label">Percent %</label> }
                                <input type='number' className='form-control'
                                       min={0.1}
                                       step={0.01}
                                       value={numberAsString(tr.percent)}
                                       onChange={handleRowChangeRawInput(rowIdx, 'percent')}
                                />
                            </div>


                            <div className="col">
                                { rowIdx ==0 && <label className="form-label">Minimum</label> }
                                <input type='number' className='form-control'
                                       min={0.1}
                                       step={0.01}
                                       value={numberAsString(tr.minAmount)}
                                       onChange={handleRowChangeRawInput(rowIdx, 'minAmount')}
                                />
                            </div>

                            {
                                shouldDisplayRemoveButton &&
                                <div className="col-1 d-flex align-items-end">
                                    <button type='button' className={classNames('btn btn-sm btn-outline-danger', { 'd-none': validatedState.data.scaleArray.length <= 2 || rowIdx == validatedState.data.scaleArray.length-1})} onClick={handleRowClickAddRemove(rowIdx, 'remove')}>&times;</button>
                                </div>
                            }

                        </div>
                    )
                })
            }

            {
                shouldDisplayAddButton &&
                <div className='pb-3'>
                    <button type='button' className='btn btn-sm btn-outline-secondary' onClick={handleRowClickAddRemove(validatedState.data.scaleArray.length-2, 'add')}>
                        Add row
                    </button>
                </div>
            }


            {
                isTypeSelected && <>
                    <label className='pt-3 form-label' htmlFor="cap_amount_input">Cap amount {shouldDisplayScale ? '(leave empty to not use a cap)' : ''}</label>
                    <input type='number'
                           id='cap_amount_input'
                           className={classNames('form-control', {'border-danger bg-warning': validatedState.capAmountValidationError})}
                           min={1}
                           step={0.01}
                           value={numberAsString(validatedState.data.capAmount)}
                           onChange={onChangeCapInput}
                    />
                </>
            }

        </div>
    )

}
