declare const FroalaEditor;

import {NextLotADMIN} from "../../nextlot_admin_data";

// !!! Manually keep in sync with app/services/html_sanitizers.rb !!!
// Allowed tags
const limitedAllowedTags = ['p', 'br', 'strong', 'b', 'h1', 'h2', 'h3', 'a'];
const defaultAllowedTags = [
    'div', 'span', 'em', 'i', 'h4', 'h5', 'ul', 'ol', 'li', 'u', 's', 'sub', 'sup',
    'table', 'tr', 'td', 'thead', 'tbody', 'tfoot', 'th', 'caption', 'blockquote',
    'dd', 'dl', 'dt', 'small', 'hr', 'summary', 'details'].concat(limitedAllowedTags);
const extraAllowedTags = ['img', 'iframe'].concat(defaultAllowedTags);
// Allowed attributes
const limitedAllowedAttrs = ['href', 'rel', 'target'];
const defaultAllowedAttrs = ['style', 'class'].concat(limitedAllowedAttrs);
const extraAllowedAttrs = ['width', 'height', 'frameborder', 'allowfullscreen', 'allow', 'src', 'title', 'alt'].concat(defaultAllowedAttrs);
// Allowed style properties
const limitedAllowedStyleProps = [];
const defaultAllowedStyleProps = ['list-style-type', 'text-align', 'margin', 'margin-right', 'margin-left', 'font-size'];
const extraAllowedStyleProps = ['color', 'line-height', 'width', 'height', 'display', 'vertical-align', 'clear'].concat(defaultAllowedStyleProps);


const LIQUID_TAGS = [
    '{{bidder.full_name}}',
    '{{bidder.first_name}}',
    '{{bidder.last_name}}',
    '{{bidder.email}}',
    '{{site.name}}',
    '{{invoice.total_amount}}',
    '{{invoice.number}}',
    '{{invoice.url}}',
    '{{auction.name}}'
];

FroalaEditor.DefineIcon('liquidMarkupTagsIcon', {
    NAME: '{{TAGS}}',
    template: 'text'
})

FroalaEditor.RegisterCommand('liquidMarkupTags', {
    title: 'Template tags will be expanded',
    icon: 'liquidMarkupTagsIcon',
    type: 'dropdown',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    options: LIQUID_TAGS,
    callback: function(cmd, val) {
        //console.log('cmd:', cmd, 'val:', val);
        this.html.insert(LIQUID_TAGS[val]);
        this.undo.saveStep();
    },
});


const HTML_EDITOR_TYPES = {

    'terms': {
        froala_options: {
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
                        'fontSize', 'inlineClass', 'clearFormatting'],
                    'buttonsVisible': 2,
                },
                'moreParagraph': {
                    'buttons': ['alignLeft', 'formatOLSimple', 'paragraphFormat',
                        'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL',
                        'paragraphStyle', 'outdent', 'indent', 'quote']
                },
                'moreRich': {
                    'buttons': ['insertLink', 'insertTable', 'specialCharacters', 'insertHR'],
                    'buttonsVisible': 4
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'selectAll', 'html', 'help'],
                    'align': 'right',
                    'buttonsVisible': 2
                }
            },
            htmlAllowedTags: defaultAllowedTags,
            htmlAllowedAttrs: defaultAllowedAttrs,
            htmlAllowedStyleProps: defaultAllowedStyleProps,
            toolbarInline: false,
            quickInsertEnabled: false,
            htmlUntouched: true,
            paragraphFormat: {
                N: 'Normal',
                H1: 'Heading 1',
                H2: 'Heading 2',
                H3: 'Heading 3',
                H4: 'Heading 4',
                H5: 'Heading 5',
            },
            linkInsertButtons: ['linkBack'],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle',
                '-', 'tableCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle'],

        }
    },

    'pages': {
        froala_options: {
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
                        'fontSize', 'textColor', 'inlineClass', 'clearFormatting'],
                    'buttonsVisible': 2,
                },
                'moreParagraph': {
                    'buttons': ['alignLeft', 'formatOLSimple', 'paragraphFormat',
                        'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL',
                        'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                },
                'moreRich': {
                    'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable',
                        'fontAwesome', 'specialCharacters', 'insertHR']
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'spellChecker', 'selectAll', 'html', 'help'],
                    'align': 'right',
                    'buttonsVisible': 2
                }
            },
            htmlAllowedTags: extraAllowedTags,
            htmlAllowedAttrs: extraAllowedAttrs,
            htmlAllowedStyleProps: extraAllowedStyleProps,
            toolbarInline: false,
            quickInsertEnabled: false,
            htmlUntouched: true,
            paragraphFormat: {
                N: 'Normal',
                H1: 'Heading 1',
                H2: 'Heading 2',
                H3: 'Heading 3',
                H4: 'Heading 4',
                H5: 'Heading 5',
            },
            linkInsertButtons: ['linkBack'],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle',
                '-', 'tableCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle'],
            // https://froala.com/wysiwyg-editor/docs/options/#image
            imageInsertButtons: ['imageByURL'],
            imageUploadRemoteUrls: false,
            // https://froala.com/wysiwyg-editor/docs/options/#video
            videoAllowedProviders: ['youtube'],
            videoEditButtons: ['videoReplace', 'videoRemove', 'videoAlign', 'videoSize', 'autoplay'],
            videoInsertButtons: ['videoBack', 'videoByURL', 'videoEmbed'],
            videoUpload: false
        },
    },

    'item_descriptions': {
        froala_options: {
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
                        'fontSize', 'inlineClass', 'clearFormatting'],
                    'buttonsVisible': 2,
                },
                'moreParagraph': {
                    'buttons': ['alignLeft', 'formatOLSimple', 'paragraphFormat',
                        'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL',
                        'paragraphStyle', 'outdent', 'indent', 'quote']
                },
                'moreRich': {
                    'buttons': ['insertLink', 'insertTable', 'specialCharacters', 'insertHR'],
                    'buttonsVisible': 4
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'selectAll', 'html', 'help'],
                    'align': 'right',
                    'buttonsVisible': 2
                }
            },
            htmlAllowedTags: defaultAllowedTags,
            htmlAllowedAttrs: defaultAllowedAttrs,
            htmlAllowedStyleProps: defaultAllowedStyleProps,
            toolbarInline: false,
            quickInsertEnabled: false,
            htmlUntouched: true,
            paragraphFormat: {
                N: 'Normal',
                H1: 'Heading 1',
                H2: 'Heading 2',
                H3: 'Heading 3',
                H4: 'Heading 4',
                H5: 'Heading 5',
            },
            linkInsertButtons: ['linkBack'],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle',
                '-', 'tableCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle'],
        }
    },

    'emails': {
        froala_options: {
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'clearFormatting'],
                    'buttonsVisible': 2,
                },
                'moreParagraph': {
                    'buttons': ['paragraphFormat'],
                    'buttonsVisible': 1
                },
                'moreRich': {
                    'buttons': ['insertLink'],
                    'buttonsVisible': 1
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'selectAll', 'html', 'help'],
                    'align': 'right',
                    'buttonsVisible': 2
                }
            },
            htmlAllowedTags: limitedAllowedTags,
            htmlAllowedAttrs: limitedAllowedAttrs,
            htmlAllowedStyleProps: limitedAllowedStyleProps,
            toolbarInline: false,
            quickInsertEnabled: false,
            htmlUntouched: true,
            paragraphFormat: {
                N: 'Normal',
                H1: 'Heading 1',
                H2: 'Heading 2',
                H3: 'Heading 3'
            },
            linkInsertButtons: ['linkBack'],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove']
        }
    },

    'banners': {
        froala_options: {
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
                        'fontSize', 'textColor', 'inlineClass', 'clearFormatting'],
                    'buttonsVisible': 2,
                },
                'moreParagraph': {
                    'buttons': ['alignLeft', 'formatOLSimple', 'paragraphFormat',
                        'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL',
                        'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                },
                'moreRich': {
                    'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable',
                        'fontAwesome', 'specialCharacters', 'insertHR']
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'spellChecker', 'selectAll', 'html', 'help'],
                    'align': 'right',
                    'buttonsVisible': 2
                }
            },
            htmlAllowedTags: extraAllowedTags,
            htmlAllowedAttrs: extraAllowedAttrs,
            htmlAllowedStyleProps: extraAllowedStyleProps,
            toolbarInline: false,
            quickInsertEnabled: false,
            // pluginsEnabled: ['link', 'codeView', 'codeBeautifier', 'entities', 'paragraphFormat', 'image'],
            htmlUntouched: true,
            paragraphFormat: {
                N: 'Normal',
                H1: 'Heading 1',
                H2: 'Heading 2',
                H3: 'Heading 3',
                H4: 'Heading 4',
                H5: 'Heading 5',
            },
            linkInsertButtons: ['linkBack'],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle',
                '-', 'tableCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle'],
            // https://froala.com/wysiwyg-editor/docs/options/#image
            imageInsertButtons: ['imageByURL'],
            imageUploadRemoteUrls: false,
            // https://froala.com/wysiwyg-editor/docs/options/#video
            videoAllowedProviders: ['youtube'],
            videoEditButtons: ['videoReplace', 'videoRemove', 'videoAlign', 'videoSize', 'autoplay'],
            videoInsertButtons: ['videoBack', 'videoByURL', 'videoEmbed'],
            videoUpload: false
        },
    },

    'invoices': {
        froala_options: {
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'fontSize', 'paragraphFormat', 'clearFormatting', 'insertLink'],
                    'buttonsVisible': 5,
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'spellChecker', 'selectAll', 'html'],
                    'align': 'right',
                    'buttonsVisible': 5
                }
            },
            htmlAllowedTags: limitedAllowedTags.concat(['span']),
            htmlAllowedAttrs: limitedAllowedAttrs.concat(['style']),
            htmlAllowedStyleProps: ['font-size'],
            toolbarInline: false,
            quickInsertEnabled: false,
            htmlUntouched: true,
            paragraphFormat: {
                N: 'Normal',
                H1: 'Heading 1',
                H2: 'Heading 2',
                H3: 'Heading 3',
            },
            linkInsertButtons: ['linkBack'],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            charCounterMax: 2000,
        },
    },


    'invoice_email_template': {
        froala_options: {
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'fontSize', 'paragraphFormat', 'clearFormatting', 'insertLink', 'liquidMarkupTags'],
                    'buttonsVisible': 6,
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'spellChecker', 'selectAll', 'html'],
                    'align': 'right',
                    'buttonsVisible': 5
                }
            },
            htmlAllowedTags: limitedAllowedTags.concat(['span']),
            htmlAllowedAttrs: limitedAllowedAttrs.concat(['style']),
            htmlAllowedStyleProps: ['font-size'],
            toolbarInline: false,
            quickInsertEnabled: false,
            htmlUntouched: true,
            paragraphFormat: {
                N: 'Normal',
                H1: 'Heading 1',
                H2: 'Heading 2',
                H3: 'Heading 3',
            },
            linkInsertButtons: ['linkBack'],
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        },
    },
}



function renderFroalaEditor(textareaElement) {

    const editorTypeCode = textareaElement.dataset.actsAsHtmlEditor.toLowerCase();
    const editorType = HTML_EDITOR_TYPES[editorTypeCode];

    if (!editorType) {
        throw `invalid HtmlEditor type: ${editorTypeCode} found for element: ${textareaElement}`;
    }

    // const uploadUrl = textareaElement.dataset.uploadUrl;

    const froalaOptions = {
        ... editorType.froala_options,
        key: NextLotADMIN.DATA.froala_key,
        attribution: false,
    }
    new FroalaEditor(textareaElement, froalaOptions);
}


function renderIfNeeded() {
    document.querySelectorAll('textarea[data-acts-as-html-editor]').forEach((node) => {
        renderFroalaEditor(node);
    });
}



export default {
    renderIfNeeded
}
