import React from "react";
import axios, {AxiosResponse} from "axios";
import {TaggedLogger} from "@nextlot/core/utilities";
import {NextLotADMIN} from "../../nextlot_admin_data";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import AsyncCreatableSelect from "react-select/async-creatable";

const _logger = TaggedLogger.get('InventoryItemsRefnoSearchbox');

export type InventoryItemOptionType = {
    id: number,
    refno: string,
    name: string,
    status_id: number,
    isNew: boolean
}

type CmpProps = {
    defaultInputValue:string,
    onRefnoSelected:(refno:string|null)=>void
}

const SEARCH_TEXT_MIN_LENGTH = NextLotJSDATA.limits.inventory_refno_min_length;

function validInputValue(inputValue:string):string {
    return (inputValue && inputValue.length >= SEARCH_TEXT_MIN_LENGTH) ? inputValue : '';
}

function executeAjaxSearch(inputValue:string):Promise<InventoryItemOptionType[]> {
    // _logger.debug('.executeAjaxSearch: inputValue:', inputValue);
    if (validInputValue(inputValue)) {
        return axios.get(NextLotADMIN.DATA.urls.ajax_inventory_search, { params: { refno: inputValue }})
            .then((axiosResponse:AxiosResponse<InventoryItemOptionType[]>) => {
                return axiosResponse.data;
            });
    }
    else {
        return Promise.resolve([]);
    }
}




export default function InventoryItemsRefnoSearchbox(props:CmpProps) {
    const existingInputHiddenElValue = props.defaultInputValue || '';

    const [defaultValue, setDefaultValue] = React.useState(undefined);
    const [defaultOptions, setDefaultOptions] = React.useState([]);

    React.useEffect(() => {
        executeAjaxSearch(existingInputHiddenElValue)
            .then((data) => {
                setDefaultOptions(data);
                if (existingInputHiddenElValue === data[0]?.refno) {
                    setDefaultValue({...data[0], name: undefined}); // reset name to trick `formatOptionLabel` into showing only refno
                }
            })
    }, []);


    const loadOptions = (inputValue: string) => new Promise<InventoryItemOptionType[]>((resolve) => {
        executeAjaxSearch(inputValue).then((data: InventoryItemOptionType[]) => {
            setDefaultOptions(data);
            resolve(data);
        });
    });

    const getNewOptionData = (inputValue: string, optionLabel) => {
        return { refno: inputValue, name: optionLabel, isNew: true }
    }

    const formatOptionLabel = (option: InventoryItemOptionType) => {
        if (option.refno.length < SEARCH_TEXT_MIN_LENGTH) {
            return `Type at least ${SEARCH_TEXT_MIN_LENGTH} characters to search ...`
        }
        if (option.isNew) {
            return <>
                <div>
                    Add new: <b>{option.refno}</b>
                </div>
            </>
        }
        if (option.name) {
            return <>
                <div>
                    {option.refno}
                </div>
                <div>
                    <em className='small'>{option.name?.substring(0, 25)}</em>
                </div>
            </>
        }

        return <>
            <div>
                {option.refno}
            </div>
        </>
    };

    const onChange = (newValue, actionMeta) => {
        // _logger.debug('.onChange:', newValue, actionMeta);

        switch(actionMeta.action) {
            case 'select-option': {
                props.onRefnoSelected(newValue.refno);
                setDefaultValue({refno: newValue.refno});
                return;
            }
            case 'create-option': {
                if (newValue.refno.length >= SEARCH_TEXT_MIN_LENGTH) {
                    props.onRefnoSelected(newValue.refno);
                    setDefaultValue({refno: newValue.refno});
                }
                return;
            }
            case 'clear': {
                props.onRefnoSelected(null);
                setDefaultValue(null);
                setDefaultOptions([]);
                return;
            }
        }
    };

    // _logger.debug(".defaultValue: ", defaultValue);


    return <AsyncCreatableSelect
        value={defaultValue}

        placeholder={`Type at least ${SEARCH_TEXT_MIN_LENGTH} characters to search ...`}
        noOptionsMessage={(input) => `Type at least ${SEARCH_TEXT_MIN_LENGTH} characters to search ...`}
        isClearable={true}
        escapeClearsValue={false}

        defaultOptions={defaultOptions}
        loadingMessage={()=>'Searching...'}
        loadOptions={loadOptions}
        allowCreateWhileLoading={false}

        createOptionPosition={'first'}

        getOptionValue={option => option.refno}
        getNewOptionData={getNewOptionData}
        formatOptionLabel={formatOptionLabel}

        onChange={onChange}
    />
};
