import {Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('FiltersAppendController');

export default class FiltersAppendController extends Controller {
  static targets = [ 'anchorAppendFilters' ]

  static values = {
    storeKey: {type: String, default: 'listing_filters_session_store'},
  };

  declare readonly hasAnchorAppendFiltersTarget: boolean;
  declare readonly anchorAppendFiltersTarget: HTMLAnchorElement;

  declare readonly storeKeyValue: string
  declare readonly hasStoreKeyValue: boolean

  connect() {
    _logger.debug('.connect()')

    if (this.hasAnchorAppendFiltersTarget) {
      this.appendSessionStoreURLParams(this.anchorAppendFiltersTarget)
    }
  }

  disconnect() {
      _logger.debug('.disconnect()')
  }

  appendSessionStoreURLParams(targetElem: HTMLAnchorElement) {
    const filtersSessionStore = sessionStorage.getItem(this.storeKeyValue)

    if(filtersSessionStore === null) {
      _logger.warn('Session storage key does not exists. Reading the key name value:', this.storeKeyValue)
      return
    }

    const urlObj = new URL(targetElem.getAttribute('href'), document.baseURI);
    targetElem.setAttribute('href', urlObj.origin + urlObj.pathname)

    if (filtersSessionStore.trim() !== '') {
      targetElem.setAttribute('href', urlObj.origin + urlObj.pathname + filtersSessionStore)
    }
  }

}
