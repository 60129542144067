import {type ActionEvent, Controller} from "@hotwired/stimulus";
// import {TaggedLogger} from "@nextlot/core/utilities";

// const _logger = TaggedLogger.get('BidderManagementController');
export default class BidderManagementController extends Controller {
    static targets = [ 'biddersManagementRegistrationUrl' ];

    declare readonly biddersManagementRegistrationUrlTarget: HTMLInputElement;


    connect() {
        // _logger.debug('.connect()');
    }

    disconnect() {
        // _logger.debug('.disconnect()');
    }


    onChangeType(event:ActionEvent) {
        if (event.target instanceof HTMLSelectElement) {
            // _logger.debug('>>> Bidders Management type selected:', `${event.target.options[event.target.selectedIndex].text} = ${event.target.value}` || 'Any')
            if (event.target.value === '0') {
                this.biddersManagementRegistrationUrlTarget.disabled = true;
                this.biddersManagementRegistrationUrlTarget.required = false;
                this.biddersManagementRegistrationUrlTarget.value = null;
            } else {
                this.biddersManagementRegistrationUrlTarget.disabled = false;
                this.biddersManagementRegistrationUrlTarget.required = true;
            }
        }
    }




}
