import DromoUploader, {
    IDeveloperField,
    IDeveloperSettings,
    IUser,
    IValidatorField,
} from "dromo-uploader-js";
import {TaggedLogger} from "@nextlot/core/utilities";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {NextLotADMIN} from "../nextlot_admin_data";



const _logger = TaggedLogger.get('DromoUploaderService');


export enum DromoImportIdentifiersEnum {
    // auction-level
    auction_lots_with_refno = 'auction_lots_with_refno',
    auction_lots_no_refno = 'auction_lots_no_refno',
    auction_place_bids = 'auction_place_bids',
    // site-level
    inventory_items = 'inventory_items',
    bidders = 'bidders',
}

// https://developer.dromo.io/settings/
const DROMO_SETTINGS_DEFAULTS:Partial<IDeveloperSettings> = {
    invalidDataBehavior: 'REMOVE_INVALID_ROWS',
    manualInputDisabled: false,
    displayEncoding: true,
    backendSyncMode: 'FULL_DATA',
    allowCustomFields: false,
    autoMapHeaders: true,
}

export const BULK_IMPORT_TYPE_ID_BY_IMPORT_IDENTIFIER: Record<DromoImportIdentifiersEnum, number> = {
    [DromoImportIdentifiersEnum.auction_lots_no_refno]: NextLotJSDATA.dromo.bulk_import_types_ids.lots_no_refno,
    [DromoImportIdentifiersEnum.auction_lots_with_refno]: NextLotJSDATA.dromo.bulk_import_types_ids.lots_with_refno,
    [DromoImportIdentifiersEnum.auction_place_bids]: NextLotJSDATA.dromo.bulk_import_types_ids.place_bids,
    [DromoImportIdentifiersEnum.inventory_items]: NextLotJSDATA.dromo.bulk_import_types_ids.inventory_items,
    [DromoImportIdentifiersEnum.bidders]: NextLotJSDATA.dromo.bulk_import_types_ids.bidders,
}



const VALIDATOR_numberAmount:IValidatorField = {
    validate: "regex_match",
    regex: "^\\$?\\d+(,\\d{3})*(\\.\\d*)?$",
    errorMessage: "Must be a positive number"
};



const FIELDS_BASE_INVENTORY_ITEM:IDeveloperField[] = [
    {
        label: "Inventory RefNo",
        key: NextLotJSDATA.dromo.fields_keys.inventory_refno,
        type: 'string',
        description: "Inventory refno of attached inventory_item for this lot; leave empty if you want it to be assigned by system",
        alternateMatches: ["inventory item refno", "inventory item reference no", "inventory #", "inventory number", "refno", "item refno", "item #"],
        validators: [
            {
                validate: 'required'
            },
            {
                validate: "unique"
            },
            {
                validate: "regex_match",
                regex: new RegExp('^\\w{' + NextLotJSDATA.limits.inventory_refno_min_length + ',32}$', 'm'),
                level: "error",
                errorMessage: `Inventory RefNo must have between ${NextLotJSDATA.limits.inventory_refno_min_length} and 32 characters, without any spaces, just letters, numbers and '_'`,
            },
        ]
    },
    {
        label: "Name",
        key: NextLotJSDATA.dromo.fields_keys.inventory_item_name,
        type: 'string',
        alternateMatches: ['lot name', 'item name', 'inventory item name', 'title', 'item title'],
        validators: [
            {
                validate: 'required'
            }
        ]
    },
    {
        label: "Description (optional)",
        key: NextLotJSDATA.dromo.fields_keys.inventory_item_description,
        type: 'string',
        alternateMatches: ['description', 'item description', 'description html'],
    },
    {
        label: "Quantity (optional, leave blank for 1)",
        key: NextLotJSDATA.dromo.fields_keys.inventory_item_quantity,
        type: ['number', 'decimal_4'],
        description: "Quantity (for items with quantity)",
        alternateMatches: ["qty", 'quantity'],
        validators: [
            VALIDATOR_numberAmount
        ]
    },
    {
        label: "Video YouTube URL (optional)",
        key: NextLotJSDATA.dromo.fields_keys.inventory_item_video_url,
        type: 'string',
        alternateMatches: ["video", "youtube", "video url"],
        validators: [
            {
                validate: "regex_match",
                regex: "^(https?|http?):\\/\\/(www\\.)?(youtube\\.com|youtu\\.be|vimeo\\.com)\\/.+$",
                errorMessage: "Must be a Youtube or Vimeo url"
            }
        ]
    },
];

const MAP_BY_KEY_FIELDS_BASE_INVENTORY_ITEM:Map<string, IDeveloperField> = new Map(FIELDS_BASE_INVENTORY_ITEM.map(f => [f.key, f]));


const FIELDS_BASE_AUCTION_LOT:IDeveloperField[] = [
    {
        label: "Lot #",
        key: NextLotJSDATA.dromo.fields_keys.lot_number,
        type: 'string',
        description: "Lot # number to be assigned",
        alternateMatches: ["lot number", "lot no", "lot", "number", "#"],
        validators: [
            {
                validate: "required",
            },
            {
                validate: "unique",
            }
        ]
    },
    {
        label: "Starting Bid (optional, leave blank for zero)",
        key: NextLotJSDATA.dromo.fields_keys.lot_starting_bid_amount,
        type: ['number', 'decimal_2'],
        alternateMatches: ["starting", "start bid"],
        validators: [
            VALIDATOR_numberAmount
        ]
    },
    {
        label: "Reserve Price (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_reserve_price,
        type: ['number', 'decimal_2'],
        alternateMatches: ["reserve", "reserve price"],
        validators: [
            VALIDATOR_numberAmount
        ]
    },
    {
        label: "Buy Now Price (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_buy_now_price,
        type: ['number', 'decimal_2'],
        alternateMatches: ["buy now price"],
        validators: [
            VALIDATOR_numberAmount
        ]
    },
    {
        label: "Alternate Sort # (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_custom_sort,
        type: ['number', 'integer'],
        alternateMatches: ["alt sort #", "sort #", "sort", "custom sort", "alt sort", "alt order"],
        validators: [
            VALIDATOR_numberAmount
        ]
    },
    {
        label: "Link (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_link_name,
        type: 'string',
        alternateMatches: ["link", "link name"],
    },
    {
        label: "Tax Rate Code (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_tax_rate_code,
        type: 'string',
        alternateMatches: ["tax rate code", "tax code", "rate code"],
    },
    {
        label: "Buyer Premium Not Taxable (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_buyer_premium_not_taxable,
        type: 'string',
        alternateMatches: ["buyer premium not taxable", "buyer premium non taxable", "premium not taxable", "premium non taxable"],
    },
    {
        label: "Payment Restricted (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_item_restricted_online_payment,
        type: 'string',
        alternateMatches: ["card payment restricted", "Card Payment Restricted (optional)", "payment restricted"],
    },
    {
        label: "Invoice Fee 1 Description (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_1_description,
        type: 'string',
        alternateMatches: ['invoice fee 1 description', 'invoice fee 1 desc', 'fee 1 description'],
        validators: [
            {
                validate: "require_with",
                fields: [NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_1_amount],
                errorMessage: "Invoice fee 1 description is required when invoice fee 1 amount is set"
            },
        ]
    },
    {
        label: "Invoice Fee 1 amount (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_1_amount,
        type: ['number', 'decimal_2'],
        alternateMatches: ['invoice fee 1 amount', 'fee 1 amount'],
        validators: [
            VALIDATOR_numberAmount
        ]
    },
    {
        label: "Invoice Fee 1 Tax Rate Code (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_1_tax_rate_code,
        type: 'string',
        alternateMatches: ["invoice fee 1 tax rate code", "fee 1 tax code", "fee 1 rate code"],
        validators: [
            {
                validate: "require_with",
                fields: [NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_1_amount],
                errorMessage: "Invoice fee 1 tax rate code is required when invoice fee 1 amount is set"
            },
        ]
    },
    {
        label: "Invoice Fee 2 Description (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_2_description,
        type: 'string',
        alternateMatches: ['invoice fee 2 description', 'invoice fee 2 desc', 'fee 2 description'],
        validators: [
            {
                validate: "require_with",
                fields: [NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_2_amount],
                errorMessage: "Invoice fee 2 description is required when invoice fee 2 amount is set"
            },
        ]
    },
    {
        label: "Invoice Fee 2 amount (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_2_amount,
        type: ['number', 'decimal_2'],
        alternateMatches: ['invoice fee 2 amount', 'fee 2 amount'],
        validators: [
            VALIDATOR_numberAmount
        ]
    },
    {
        label: "Invoice Fee 2 Tax Rate Code (optional)",
        key: NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_2_tax_rate_code,
        type: 'string',
        alternateMatches: ["invoice fee 2 tax rate code", "fee 2 tax code", "fee 2 rate code"],
        validators: [
            {
                validate: "require_with",
                fields: [NextLotJSDATA.dromo.fields_keys.lot_invoice_fee_2_amount],
                errorMessage: "Invoice fee 2 tax rate code is required when invoice fee 2 amount is set"
            },
        ]
    },

    {
        label: "Image URL strategy (optional)",
        key: NextLotJSDATA.dromo.fields_keys.inventory_item_imgurl_strategy,
        type: 'string',
        alternateMatches: ["URL strategy", "img URL strategy"],
        validators: [
            {
                validate: "require_with",
                fields: [NextLotJSDATA.dromo.fields_keys.inventory_item_imgs_urls],
                errorMessage: "Image URL strategy is required when Images URLs is set"
            },
        ]
    },
    {
        label: "Image URL variants (optional)",
        key: NextLotJSDATA.dromo.fields_keys.inventory_item_imgurl_variants,
        type: 'string',
        alternateMatches: ['URL variants', 'img URL variants'],
        validators: [
            {
                validate: "require_with",
                fields: [NextLotJSDATA.dromo.fields_keys.inventory_item_imgs_urls],
                errorMessage: "Image URL variants is required when Images URLs is set"
            },
        ]
    },
    {
        label: "Images URLs (optional)",
        key: NextLotJSDATA.dromo.fields_keys.inventory_item_imgs_urls,
        type: 'string',
        alternateMatches: ['URLs', 'img URLs'],
    },
];

const MAP_BY_KEY_FIELDS_BASE_AUCTION_LOT:Map<string, IDeveloperField> = new Map(FIELDS_BASE_AUCTION_LOT.map(f => [f.key, f]));


const FIELDS_AUCTION_LOTS_NO_REFNO = mergeArraysOfFields(FIELDS_BASE_AUCTION_LOT, [
    {
        ... MAP_BY_KEY_FIELDS_BASE_INVENTORY_ITEM.get(NextLotJSDATA.dromo.fields_keys.inventory_item_name),
        label: 'Lot Name',
    },
    {
        ... MAP_BY_KEY_FIELDS_BASE_INVENTORY_ITEM.get(NextLotJSDATA.dromo.fields_keys.inventory_item_description),
        label: 'Lot Description (optional)',
    },
    MAP_BY_KEY_FIELDS_BASE_INVENTORY_ITEM.get(NextLotJSDATA.dromo.fields_keys.inventory_item_quantity),
    MAP_BY_KEY_FIELDS_BASE_INVENTORY_ITEM.get(NextLotJSDATA.dromo.fields_keys.inventory_item_video_url),
]);

const FIELDS_AUCTION_LOTS_WITH_REFNO = mergeArraysOfFields(FIELDS_AUCTION_LOTS_NO_REFNO, [

    MAP_BY_KEY_FIELDS_BASE_INVENTORY_ITEM.get(NextLotJSDATA.dromo.fields_keys.inventory_refno),

    {
        key: NextLotJSDATA.dromo.fields_keys.inventory_item_name,
        validators:[
            {
                validate: "require_without",
                fields: [NextLotJSDATA.dromo.fields_keys.inventory_refno],
                errorMessage: "Name is required when the inventory RefNo is not provided"
            }
        ]
    }
]);


const FIELDS_AUCTION_BIDS:IDeveloperField[] = [
    MAP_BY_KEY_FIELDS_BASE_AUCTION_LOT.get(NextLotJSDATA.dromo.fields_keys.lot_number),

    {
        label: "Place bid MAX amount",
        key: NextLotJSDATA.dromo.fields_keys.lot_bid_max_amount,
        type: ['number', 'decimal_2'],
        alternateMatches: ["bid", "bid amount", "bid value", "bid max", "bid max amount", "bid max value", "max amount", "max value"],
        validators: [
            VALIDATOR_numberAmount
        ]
    },
    {
        label: "Bidder number",
        key: NextLotJSDATA.dromo.fields_keys.lot_bid_bidder_number,
        type: ['number', {
            preset: 'decimal_0',
            round: 0,
            displayFormat: { thousandSeparated: false },
            outputFormat: { thousandSeparated: false }
        }],
        alternateMatches: ["bidder", "bidder number"],
        validators: [
            {
                validate: "require_with_all",
                fields: [NextLotJSDATA.dromo.fields_keys.lot_number, NextLotJSDATA.dromo.fields_keys.lot_bid_max_amount],
                errorMessage: "Bidder number is required when a Max Bid amount is set"
            },
        ]
    },
]

const FIELDS_SITE_INVENTORY_ITEMS:IDeveloperField[] = FIELDS_BASE_INVENTORY_ITEM;

const FIELDS_KEYS_BIDDERS_EXCLUDED_FROM_EMAIL_VALIDATION: Set<string> = new Set([
  `bidder_${NextLotJSDATA.dromo.fields_keys.bidder_credit_card_exempt}`,
  `bidder_${NextLotJSDATA.dromo.fields_keys.bidder_bidding_globally_approved}`,
]);

const FIELDS_SITE_BIDDERS: IDeveloperField[] = [
    {
        label: "Email",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_email,
        description: "Bidder email address",
        type: "email",
        validators: [
            {
                validate: "require_with",
                fields: Object.entries(NextLotJSDATA.dromo.fields_keys).filter(
                                                                                ([key, _]) => key.startsWith('bidder_') &&
                                                                                !FIELDS_KEYS_BIDDERS_EXCLUDED_FROM_EMAIL_VALIDATION.has(key)
                                                                              )
                                                                        .map(([_, value]) => value),
                errorMessage: "Bidder email address must be provided"
            },
            {
                validate: "unique",
                errorMessage: "Bidder email address must be unique"
            }
        ]
    },
    {
        label: "First Name",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_first_name,
        description: "Bidder first name",
    },
    {
        label: "Last Name",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_last_name,
        description: "Bidder last name",
    },
    {
        label: "Phone Number",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_phone_number,
        description: "Bidder phone number",
    },
    {
        label: "Alternate Phone Number",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_alternate_phone_number,
        description: "Bidder alternate hone number",
    },
    {
        label: "Company",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_billing_company_name,
        description: "Bidder company",
    },
    {
        label: "Sales Tax Number / VAT Number",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_billing_company_taxid,
        description: "Bidder sales tax number / vat number",
    },
    {
        label: "Address line 1",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_address_address_street,
        description: "Bidder address line 1",
    },
    {
        label: "Address line 2",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_address_address_street2,
        description: "Bidder address line 2",
    },
    {
        label: "City",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_address_city,
        description: "Bidder city",
    },
    {
        label: "State / Region",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_address_region,
        description: "Bidder state / region",
    },
    {
        label: "Postal code",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_address_postal_code,
        description: "Bidder postal code",
    },
    {
        label: "Country",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_address_country_code,
        description: "Bidder country",
    },
    {
        label: "Credit Card Exempt",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_credit_card_exempt,
        description: "Bidder is exempt to have a credit card on record",
        type: "checkbox",
    },
    {
        label: "Globally Approved",
        key: NextLotADMIN.DATA.dromo.fields_keys.bidder_bidding_globally_approved,
        description: "Bidder is globally approved for all auctions",
        type: "checkbox",
    },
]

const DROMO_FIELDS_BY_IMPORT_IDENTIFIER: Record<DromoImportIdentifiersEnum, IDeveloperField[]> = {
    [DromoImportIdentifiersEnum.auction_lots_no_refno]: FIELDS_AUCTION_LOTS_NO_REFNO,
    [DromoImportIdentifiersEnum.auction_lots_with_refno]: FIELDS_AUCTION_LOTS_WITH_REFNO,
    [DromoImportIdentifiersEnum.auction_place_bids]: FIELDS_AUCTION_BIDS,
    [DromoImportIdentifiersEnum.bidders]: FIELDS_SITE_BIDDERS,
    [DromoImportIdentifiersEnum.inventory_items]: FIELDS_SITE_INVENTORY_ITEMS
}





// TODO: remove this hack if Dromo fixes automatic decimal formatting for presumed number values
function lotNumberColumnHook(values) {
    const newValues = [];
    values.forEach((row) => {

        const newRow = {
            index: row.index,
            value: row.value.endsWith('.0') ? row.value.replace('.0', '') : row.value
        };

        newValues.push(newRow);
    });
    return newValues;
}

function quantityRowHook(record) {
    const newRecord = record;
    const recordRowQuantity = record.row[NextLotJSDATA.dromo.fields_keys.inventory_item_quantity];
    // parseFloat() will return NaN for anything not a number, and NaN is never < 1   (NaN < 1 === false)
    if (recordRowQuantity && parseFloat(recordRowQuantity.resultValue) < 1) {
        recordRowQuantity.info = [{
            message: "Quantity must be greater than or equal to 1",
            level: "error"
        }];
    }
    return newRecord;
}



function nameRowHook(record) {
    let info = [];
    const newRecord = record;
    const rowValue = record.row[NextLotJSDATA.dromo.fields_keys.inventory_item_name].value;

    if (rowValue.length > NextLotJSDATA.limits.name_max_length) {
        record.row[NextLotJSDATA.dromo.fields_keys.inventory_item_name].value = rowValue.substring(0,250);
        info.push({
            message: `Name has been trimmed to ${NextLotJSDATA.limits.name_max_length} characters`,
            level: "warning"
        });
    }

    newRecord.row[NextLotJSDATA.dromo.fields_keys.inventory_item_name].info = info;
    return newRecord;
}



function descriptionRowHook(record) {
    let info = [];
    const newRecord = record;
    const rowValue = record.row[NextLotJSDATA.dromo.fields_keys.inventory_item_description].value;

    if (rowValue?.length && rowValue.length > NextLotJSDATA.limits.description_max_length) {
        info.push({
            message: `Description must be maximum ${NextLotJSDATA.limits.description_max_length} characters.`,
            level: "error"
        });
    }

    newRecord.row[NextLotJSDATA.dromo.fields_keys.inventory_item_description].info = info;
    return newRecord;
}



function inventoryRefnoRowHook(record, mode) {
    let info = [];
    const newRecord = record;
    const rowValue = record.row[NextLotJSDATA.dromo.fields_keys.inventory_refno].value;

    if (rowValue?.length && rowValue.length < NextLotJSDATA.limits.inventory_refno_min_length) {
        info.push({
            message: `Inventory refno must be at least ${NextLotJSDATA.limits.inventory_refno_min_length} characters.`,
            level: "error"
        });
    }

    newRecord.row[NextLotJSDATA.dromo.fields_keys.inventory_refno].info = info;
    return newRecord;
}



function countryRowHook(record) {
    let info = [];
    const newRecord = record;
    const rowValue = record.row[NextLotADMIN.DATA.dromo.fields_keys.bidder_address_country_code].value;

    if (! NextLotADMIN.DATA.country_alpha2_codes.includes(rowValue) && rowValue) {
        info.push({
            message: "Not a valid country code. Get the Alpha2-code from https://www.iso.org/obp/ui/#search/code/",
            level: "error"
        });
    }

    newRecord.row[NextLotADMIN.DATA.dromo.fields_keys.bidder_address_country_code].info = info;
    return newRecord;
}



export function createDromoUploaderForAuction(settings:IDeveloperSettings, user:IUser) {
    const importIdentifier = settings.importIdentifier;

    const dromo = new DromoUploader(NextLotJSDATA.dromo.frontend_api_key,
        DROMO_FIELDS_BY_IMPORT_IDENTIFIER[importIdentifier],
        {
            ... DROMO_SETTINGS_DEFAULTS,
            ... settings
        } as IDeveloperSettings,
        user);

    dromo.registerColumnHook(NextLotJSDATA.dromo.fields_keys.lot_number, lotNumberColumnHook);

    if (importIdentifier === DromoImportIdentifiersEnum.auction_lots_no_refno || importIdentifier === DromoImportIdentifiersEnum.auction_lots_with_refno) {
        dromo.registerRowHook(nameRowHook);
        dromo.registerRowHook(quantityRowHook);
    }

    return dromo;
}

export function createDromoUploaderForSite(settings:IDeveloperSettings, user:IUser) {
    const importIdentifier = settings.importIdentifier;

    const dromo = new DromoUploader(NextLotJSDATA.dromo.frontend_api_key,
        DROMO_FIELDS_BY_IMPORT_IDENTIFIER[importIdentifier],
        {
            ... DROMO_SETTINGS_DEFAULTS,
            ... settings
        } as IDeveloperSettings,
        user);


    if (importIdentifier === DromoImportIdentifiersEnum.inventory_items) {
        dromo.registerRowHook(nameRowHook);
        dromo.registerRowHook(descriptionRowHook);
        dromo.registerRowHook(quantityRowHook);
        dromo.registerRowHook(inventoryRefnoRowHook);
    }

    if (importIdentifier === DromoImportIdentifiersEnum.bidders) {
        dromo.registerRowHook(countryRowHook);
    }

    return dromo;
}


function mergeArraysOfFields(baseFields:Partial<IDeveloperField>[], updateFields:Partial<IDeveloperField>[]):IDeveloperField[] {
    const fieldsByKey:Map<string, Partial<IDeveloperField>> = new Map(baseFields.map((f) => ([f.key, f])));

    updateFields.forEach((uf) => {
        fieldsByKey.set(uf.key, {
            ... fieldsByKey.get(uf.key),
            ... uf
        } as IDeveloperField);
    });

    return Array.from(fieldsByKey.values()) as IDeveloperField[];
}
