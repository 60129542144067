import {TaggedLogger} from "@nextlot/core/utilities";
import {NextLotADMIN} from "../nextlot_admin_data";

const _logger = TaggedLogger.get('AuctionWebcastManage');

const RELOAD_PAGE_INTERVAL_MILLISECONDS = 5000; // same as the "simulate preparing" from webcast launch job.

function reloadPage() {
  window.location.reload();
}

function setReloadInterval(): ReturnType<typeof setInterval> {
  return setInterval(
          reloadPage,
          RELOAD_PAGE_INTERVAL_MILLISECONDS
        );
}

function checkAuctionBeforePrebidClosed() {
  // _logger.debug('.checkWebcastPreparingState>NextLotADMIN.DATA.auction_is_before_prebid_closed', NextLotADMIN.DATA.auction_is_before_prebid_closed)

  let reloadInterval;

  if(NextLotADMIN.DATA.auction_is_before_prebid_closed) {
    reloadInterval = setReloadInterval();
  } else {
    clearInterval(reloadInterval);
    reloadInterval = null;
  }
}

function checkWebcastPreparingState() {
  // _logger.debug('.checkWebcastPreparingState>NextLotADMIN.DATA.webcast_state_is_preparing', NextLotADMIN.DATA.webcast_state_is_preparing)

  let reloadInterval;

  if(NextLotADMIN.DATA.webcast_state_is_preparing) {
    reloadInterval = setReloadInterval();
  } else {
    clearInterval(reloadInterval);
    reloadInterval = null;
  }
}


export default function auctionsWebcastManageReloadPageIfNeeded() {
  checkAuctionBeforePrebidClosed();
  checkWebcastPreparingState();
}
