import {Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";
import {Modal as BsModal} from "bootstrap";

const _logger = TaggedLogger.get('PowerTurboModalController');

export default class PowerTurboModalController extends Controller {
    // static targets = ["container", "content"]
    declare bsModal: BsModal


    connect() {
        _logger.debug('.connect()')
        this.bsModal = BsModal.getOrCreateInstance(this.element)
    }

    disconnect() {
        _logger.debug('.disconnect()')
        this.bsModal?.dispose();
    }


    show() {
        this.bsModal?.show()
    }

    hide() {
        this.bsModal?.hide()
    }

}
