import React from "react";
import ReactDOM from "react-dom/client";
import {runOnDOMContentLoaded, TaggedLogger} from "@nextlot/core/utilities";
import InventoryItemsRefnoSearchbox from "./helpers/InventoryItemsRefnoSearchbox";
import {NextLotADMIN} from "../nextlot_admin_data"
import axios from "axios";

const _logger = TaggedLogger.get('lot_form.tsx');





function attachInventoryKeySearchToForm(formEl:HTMLFormElement) {
    const searchInputHiddenEl = formEl.querySelector('input[data-acts-as-inventory-refno-searchbox]') as HTMLInputElement;

    if (!searchInputHiddenEl) {
        return;
    }

    const reactSelectEl = document.createElement('div');
    searchInputHiddenEl.after(reactSelectEl);

    const inventoryItemStatusBoxEl = formEl.querySelector('#form_lot_inventory_item_status_box') as HTMLElement;
    const inventoryItemNameEl = formEl.querySelector('#lot_inventory_item_name') as HTMLInputElement;
    const froalaEditorEl = formEl.querySelector('#lot_inventory_item_description_html') as HTMLElement;
    const inventoryItemQuantityEl = formEl.querySelector('#lot_inventory_item_quantity') as HTMLInputElement;
    const inventoryItemVideoUrlEl = formEl.querySelector('#lot_inventory_item_video_url') as HTMLInputElement;

    ReactDOM.createRoot(reactSelectEl).render(
        <InventoryItemsRefnoSearchbox
            defaultInputValue={searchInputHiddenEl.value}
            onRefnoSelected={(refno) => {

                searchInputHiddenEl.value = refno ?? '';

                // @ts-ignore
                axios.get(NextLotADMIN.DATA.urls.ajax_lots_fetch_inventory_item_status, {
                    params: {
                        refno: refno ?? '',
                    }
                }).then((response) => {
                    _logger.debug('.attachInventoryKeySearchToForm: axios.get(inventory_item_status).then:', response);

                    const data = response.data;

                    // console.log("attachInventoryKeySearchToForm: ", data);
                    inventoryItemStatusBoxEl.innerHTML = data.status_box_html;

                    if (data.name) {
                        inventoryItemNameEl.value = data.name;
                    }
                    if (data.description_html) {
                        froalaEditorEl["data-froala.editor"].html.set(data.description_html);
                    }
                    if (data.quantity) {
                        inventoryItemQuantityEl.value = data.quantity;
                    }
                    if (data.video_url) {
                        inventoryItemVideoUrlEl.value = data.video_url;
                    }
                }).catch((error) => {
                    _logger.error('.attachInventoryKeySearchToForm: axios.get(inventory_item_status).catch: ERROR:', error);
                })
            }}
        />);
}




function renderIfNeeded() {
    runOnDOMContentLoaded(function () {

        const formEl_new_edit = document.getElementById('form_lot_new_edit') as HTMLFormElement;
        if (formEl_new_edit) {
            // _logger.debug('.renderIfNeeded>runOnDOMContentLoaded> Lot: ', formEl_new_edit);

            attachInventoryKeySearchToForm(formEl_new_edit);
        }

    });
}

export default {
    renderIfNeeded
}
