import {runOnDOMContentLoaded, TaggedLogger} from "@nextlot/core/utilities";
import {throttle} from "lodash";

const _logger = TaggedLogger.get('forms::Auction');

//TODO:FIXME: move this into Gon.global
const BiddingApprovalMode = {
    ID_MANUAL_BY_ADMIN: 0,
    ID_LINKED_SALE_RING: 5,
    ID_AUTO_BIDDER_SITE: 11,
    ID_AUTO_SALE: 21,
};




function mountBiddingApprovalModeRadios(formEl:HTMLFormElement) {

    _logger.debug('.mountBiddingApprovalModeRadios formEl:', formEl);

    const manualOptionsFormSectionEl:HTMLDivElement = document.getElementById('form_section_bidding_approval_mode_manual_options') as HTMLDivElement;


    let isToggleFromHidden = false;

    const handleChange = (value:string) => {
        console.log('.mountBiddingApprovalModeRadios>handleChange: value:', value);

        const valueInt = parseInt(value);

        if (valueInt === BiddingApprovalMode.ID_MANUAL_BY_ADMIN) {
            manualOptionsFormSectionEl.classList.remove('d-none');
            if (isToggleFromHidden) {
                manualOptionsFormSectionEl.classList.add('animate-fade-scale-in-flash');
                isToggleFromHidden = false;
            }
        }
        else {
            manualOptionsFormSectionEl.classList.add('d-none');
            isToggleFromHidden = true;
        }
    }


    let currentValue:string|false = false;

    formEl.querySelectorAll('input[type="radio"][name="auction[bidding_approval_mode_id]"]').forEach(function (radioEl:HTMLInputElement) {
        if (radioEl.checked) {
            currentValue = radioEl.value;
        }

        radioEl.addEventListener('change', (event) => {
            // using seTimeout to avoid warning: [Violation] Forced reflow while executing JavaScript took 99999ms
            setTimeout(() => {
                handleChange((event.target as any).value);
            });
        });
    });

    if (currentValue !== false) {
        handleChange(currentValue);
    }
}



function mountBiddingSettingsStaggering(formEl:HTMLFormElement) {
    const lastLotNoEl:HTMLInputElement = formEl.querySelector('[name="auction[timed_lots_staggering_ruleset][s1_last_lot_number]"]') as HTMLInputElement;
    const fieldsDiv:HTMLDivElement = document.getElementById('fields_auction_staggering_2nd_segment') as HTMLDivElement;

    if (lastLotNoEl) {
        const handleInputChange = (lastLotNoEl:HTMLInputElement) => {
            if (lastLotNoEl.value) {
                fieldsDiv.className = 'd-block';
            }
            else {
                fieldsDiv.className = 'd-none';
            }
        }

        lastLotNoEl.addEventListener('input', throttle(() => {
            handleInputChange(lastLotNoEl);
        }, 500));

        handleInputChange(lastLotNoEl);
    }
}



function renderIfNeeded(formElementId) {
    runOnDOMContentLoaded(function () {

        const formEl = document.getElementById(formElementId) as HTMLFormElement;
        if (! formEl) {
            return;
        }
        _logger.debug('.renderIfNeeded>runOnDOMContentLoaded> attached: ', formEl);


        mountBiddingApprovalModeRadios(formEl);
        mountBiddingSettingsStaggering(formEl);
    });
}

export default {
    renderIfNeeded
}

