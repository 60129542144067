import {Controller} from "@hotwired/stimulus";
import {Tooltip as BsTooltip} from "bootstrap";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('BootstrapJsController')

export default class BootstrapJsController extends Controller {
    static targets = ['tooltip', 'popover']


    tooltipTargetConnected(element:HTMLElement) {
        // _logger.debug('.tooltipTargetConnected: ', element)
        BsTooltip.getOrCreateInstance(element)
    }

    tooltipTargetDisconnected(element:HTMLElement) {
        // _logger.debug('.tooltipTargetDisconnected: ', element)
        BsTooltip.getInstance(element)?.dispose()
    }

}
