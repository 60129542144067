import {Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";
import {createDromoUploaderForSite, DromoImportIdentifiersEnum} from "../services/DromoUploaderService";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import { IDeveloperSettings, IUser } from "dromo-uploader-js";

const _logger = TaggedLogger.get('BulkImportBiddersController');

export default class BulkImportBiddersController extends Controller {
  static targets = ["siteId", "uploadFile", "uploadFileButton", "composeEmail", "submitInvitations", "uploadId", "expectedRowsCount"];

  private readonly siteIdTarget: HTMLSelectElement;
  private readonly uploadFileTarget: HTMLFieldSetElement;
  private readonly uploadFileButtonTarget: HTMLButtonElement;
  private readonly composeEmailTarget: HTMLFieldSetElement;
  private readonly submitInvitationsTarget: HTMLButtonElement;
  private readonly uploadIdTarget: HTMLInputElement;
  private readonly expectedRowsCountTarget: HTMLInputElement;

  private dromoUser: IUser = NextLotJSDATA.dromo_user;
  private dromoSettings: IDeveloperSettings = {
    importIdentifier: DromoImportIdentifiersEnum.bidders,
    ...NextLotJSDATA.dromo_settings,
  };

  connect() {
    _logger.debug('.connect()')
  }

  disconnect() {
    _logger.debug('.disconnect()')
  }

  handleSiteChange(event: Event) {
    if (this.siteIdTarget.value) {
      const targetElem = event.target as HTMLSelectElement
      this.dromoSettings['title'] = `Bidders bulk invites for ${targetElem.options[targetElem.selectedIndex].text}`;
      this.dromoUser['companyId'] = `${this.siteIdTarget.value}`;

      // Enable Dromo import file upload and processing
      this.uploadFileTarget.classList.remove('d-none');
    } else {
      // Disable Dromo import file upload and processing
      this.uploadFileTarget.classList.add('d-none');
      this.composeEmailTarget.classList.add('d-none');
      this.submitInvitationsTarget.classList.add('d-none');
    }
  }

  handleFileImport(event: Event) {
    event.preventDefault();

    const uploadFileButtonTarget = this.uploadFileButtonTarget
    const dromo = createDromoUploaderForSite(this.dromoSettings, this.dromoUser);

    this.handleFileUploadSubmitState({submitButton: uploadFileButtonTarget, isImporting: true});

    dromo.beforeFinish((data, metadata, instance) => {
      if (data.length > 10_000) {
        return { cancel: true, message: 'Maximum of 10,000 rows (header excluded) are allowed per import file. Split in 2 or more files up to to 10,000 rows.' };
      }
    });

    dromo.onResults((dromoResponse, dromoMetadata) => {
      this.expectedRowsCountTarget.value = dromoResponse.length.toString();
      this.uploadIdTarget.value = dromoMetadata["id"];

      this.handleFileUploadSubmitState({submitButton: uploadFileButtonTarget, isImporting: false});
      this.composeEmailTarget.classList.remove('d-none');
      this.submitInvitationsTarget.classList.remove('d-none');
    });

    dromo.onCancel(() => {
      this.handleFileUploadSubmitState({submitButton: uploadFileButtonTarget, isImporting: false});
    });


    dromo.open();
  }

  handleFileUploadSubmitState({submitButton, isImporting = false}: {submitButton: HTMLButtonElement, isImporting: boolean}) {
    if(isImporting) {
      submitButton.innerHTML = '<span class="me-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Launching...'
      submitButton.disabled = true;
    } else {
      submitButton.innerHTML = 'Select file'
      submitButton.disabled = false;
    }
  }
}
