import Uppy, { UppyOptions } from '@uppy/core'
import Dashboard, { DashboardOptions } from '@uppy/dashboard';
import Transloadit, { TransloaditOptions } from '@uppy/transloadit';

type NextLotUppyOpts = {
    uppyOptions?: UppyOptions,
    uppyDashboardOptions?: DashboardOptions,
    uppyTransloaditOptions?: TransloaditOptions,


}

export function createUppyUploaderDashboard(opts: NextLotUppyOpts) {
    const uppy = new Uppy({
        debug: true,
        autoProceed: true, // maybe not always?
        ...opts.uppyOptions,
    });

    uppy.use(Dashboard, {
        trigger: '#btn_trigger_uppy_dashboard',
        showProgressDetails: true,
        proudlyDisplayPoweredByUppy: false,
        doneButtonHandler: null,
        note: `Allowed file size up to ${opts.uppyOptions.restrictions.maxFileSize / (1024*1024)} MB and file type: ${opts.uppyOptions.restrictions.allowedFileTypes.join(", ")}`,
        ...opts.uppyDashboardOptions,
    }).use(Transloadit, {
        waitForEncoding: true,
        ...opts.uppyTransloaditOptions,
    });

    return uppy;
}
