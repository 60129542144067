import {Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('FiltersSaveController');

export default class FiltersSaveController extends Controller {
  static targets = [ 'filtersSessionStore' ]
  static outlets = [ 'filters-append' ] // used only when the HTMLAnchorElement is on the same page view with `filtersSessionStoreTarget`

  static values = {
    storeKey: {type: String, default: 'listing_filters_session_store'},
  };

  declare readonly hasFiltersSessionStoreTarget: boolean;
  declare readonly filtersSessionStoreTarget: HTMLElement;

  declare readonly storeKeyValue: string
  declare readonly hasStoreKeyValue: boolean

  declare readonly hasFiltersAppendOutlet: boolean;
  declare readonly filtersAppendOutlet: Controller;

  connect() {
    _logger.debug('.connect()')

    _logger.debug('.hasListingsFiltersAppendOutlet', this.hasFiltersAppendOutlet)

    if (this.hasFiltersSessionStoreTarget) {
      this.saveURLQueryParams()

      // When the HTMLAnchorElement is on the same page with the `filtersSessionStoreTarget`
      // append the selected filters to the href attribute
      if (this.hasFiltersAppendOutlet) {
        this.outletAppendURLQueryParams()
      }
    }
  }

  disconnect() {
      _logger.debug('.disconnect()')
  }

  saveURLQueryParams() {
    const urlQueryParams = window.location.search
    sessionStorage.setItem(this.storeKeyValue, urlQueryParams)
  }

  outletAppendURLQueryParams() {
    const outlet = this.filtersAppendOutlet
    /* @ts-ignore */
    outlet.appendSessionStoreURLParams(outlet.anchorAppendFiltersTarget)
  }

  clearURLQueryParamsSessionStore() {
    sessionStorage.setItem(this.storeKeyValue, '')
  }

}
