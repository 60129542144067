import {ActionEvent, Controller} from "@hotwired/stimulus";
// import {TaggedLogger} from "@nextlot/core/utilities";

// const _logger = TaggedLogger.get('InvoicePaymentMethodsCardController');
export default class InvoicePaymentMethodOnlineCardController extends Controller {
    static targets = [ 'invoicePaymentMethodOnlineCardMaxAmount', 'invoicePaymentMethodSetting' ];

    declare readonly invoicePaymentMethodOnlineCardMaxAmountTarget: HTMLInputElement;


    connect() {
        // _logger.debug('.connect()');
    }

    disconnect() {
        // _logger.debug('.disconnect()');
    }


    onClick(event:ActionEvent) {
        if (event.target instanceof HTMLSelectElement) {
            if(event.target.value != "0") { // 0 => Site::InvoicePaymentOnlineSetting::ID_NOT_ENABLED
                this.invoicePaymentMethodOnlineCardMaxAmountTarget.disabled = false;
                this.invoicePaymentMethodOnlineCardMaxAmountTarget.required = true;
            } else {
                this.invoicePaymentMethodOnlineCardMaxAmountTarget.disabled = true;
                this.invoicePaymentMethodOnlineCardMaxAmountTarget.required = false;
                this.invoicePaymentMethodOnlineCardMaxAmountTarget.value = String(0);
            }
        }
    }




}
