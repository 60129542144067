import {NextLotADMIN} from "../../nextlot_admin_data"
import {createUppyUploaderDashboard} from "../../services/UppyUploaderService";

function renderFormSiteLogoImageUpload(formEl:HTMLFormElement) {
    const maxFileSizeMegabytes = parseInt(String(NextLotADMIN.DATA.limits.transloadit_max_file_size_megabytes));

    const uppySiteLogoImageUploadRestrictions = {
        maxNumberOfFiles: 1,
        allowMultipleUploadBatches: false,
        maxFileSize: maxFileSizeMegabytes * 1024 * 1024,
        allowedFileTypes: ['.png'],
    }

    const uppy = createUppyUploaderDashboard({
        uppyOptions: {
            restrictions: uppySiteLogoImageUploadRestrictions
        },
        uppyTransloaditOptions: {
            waitForEncoding: true, // must set `true` to have access to event 'transloadit:complete'

            params: NextLotADMIN.DATA.transloadit.params,
            signature: NextLotADMIN.DATA.transloadit.signature,
        }
    });

    uppy.on('transloadit:complete', (assembly) => {

        const inputSiteLogoUrl400 = <HTMLInputElement>document.getElementById('site_logo_url_400_X_400');
        inputSiteLogoUrl400.value = assembly.results[NextLotADMIN.DATA.transloadit.encoding_steps.step_optimized_img_logo_400_x_400][0].ssl_url;

        const inputSiteLogoUrl120 = <HTMLInputElement>document.getElementById('site_logo_url_120_X_120');
        inputSiteLogoUrl120.value = assembly.results[NextLotADMIN.DATA.transloadit.encoding_steps.step_optimized_img_logo_120_x_120][0].ssl_url;

        const inputSiteLogoUrl32 = <HTMLInputElement>document.getElementById('site_logo_url_32_X_32');
        inputSiteLogoUrl32.value = assembly.results[NextLotADMIN.DATA.transloadit.encoding_steps.step_optimized_img_logo_32_x_32][0].ssl_url;

        formEl.submit();
    })
}


function renderIfNeeded() {
    const formEl = document.getElementById('form_site_logo_image_upload') as HTMLFormElement;
    if (formEl) {
        renderFormSiteLogoImageUpload(formEl);
    }
}

export default {
    renderIfNeeded
}
