import {ActionEvent, Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('FilterToggleTaxExemptBiddersController');

export default class FilterToggleTaxExemptBiddersController extends Controller {
    static targets = [ 'checkFilerTaxExemptBidders' ];

    declare readonly hasCheckFilerTaxExemptBiddersTarget: boolean;
    declare readonly checkFilerTaxExemptBiddersTarget: HTMLInputElement;


    connect() {
        _logger.debug('.connect()');
    }

    disconnect() {
        _logger.debug('.disconnect()');
    }


    onChangePrimaryAuction(event:ActionEvent) {
        if (event.target instanceof HTMLSelectElement) {
          _logger.debug('>>> Primary auction selected:', event.target.value || 'Any')

          if (this.hasCheckFilerTaxExemptBiddersTarget) {
            this.checkFilerTaxExemptBiddersTarget.disabled = event.target.value == '';
            this.checkFilerTaxExemptBiddersTarget.checked = false; // make sure is unchecked when changing the primary auction value
          }
        }
    }




}
